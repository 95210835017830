import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60dc8534"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-5 flex gap-3 w-96" }
const _hoisted_2 = {
  key: 0,
  class: "group-search__title inline-block relative text-xl",
  style: {"font-size":"2rem","position":"initial","transition":"none !important","margin-right":"0.3em !important"}
}
const _hoisted_3 = { class: "animate-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.text), 1)
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}