import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_phone_number = _resolveComponent("phone-number")!
  const _component_label_output = _resolveComponent("label-output")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("p", null, _toDisplayString(_ctx.user.firstName + ' ' + (_ctx.user.lastName ? _ctx.user.lastName : '')), 1)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("p", null, _toDisplayString(_ctx.user.email), 1)
    ]),
    _createVNode(_component_phone_number, {
      hasWarning: true,
      phoneNumber: _ctx.user.phoneNumber
    }, null, 8, ["phoneNumber"]),
    _createVNode(_component_label_output, {
      label: "Lidnummer",
      text: _ctx.user.membershipNumber
    }, null, 8, ["text"])
  ], 64))
}