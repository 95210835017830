
import VehicleItem from '@/components/insurances/travelAssistance/vehicleItem.vue'
import { scrollToFirstError, useFormSendWithSuccess } from '@/veeValidate/helpers'
import { computed, defineComponent, PropType, ref, toRefs, watch } from 'vue'
import { VehicleTypeRepository } from '@/repositories/vehicleTypeRepository'
import { VehicleRepository } from '@/repositories//vehicleRepository'
import { TrailerRepository } from '@/repositories/trailerRepository'
import { ResponsibleMember } from '@/serializer/ResponsibleMember'
import RepositoryFactory from '@/repositories/repositoryFactory'
import CustomInput from '@/components/inputs/CustomInput.vue'
import SearchInput from '@/components/inputs/SearchInput.vue'
import MultiSelect from '@/components/inputs/MultiSelect.vue'
import CustomButton from '@/components/CustomButton.vue'
import { VehicleType } from '@/serializer/VehicleType'
import { InputTypes } from '@/enums/inputTypes'
import { Vehicle } from '@/serializer/Vehicle'
import { useForm } from 'vee-validate'
import { useStore } from 'vuex'
import { BaseSideBar, sideBarState, option } from 'vue-3-component-library'
import { InsuranceTypes } from '@/enums/insuranceTypes'

export interface vehicleSideBar {
  vehicle: Vehicle
}

export default defineComponent({
  name: 'VehicleSideBar',
  components: {
    'custom-button': CustomButton,
    'base-side-bar': BaseSideBar,
    'custom-input': CustomInput,
    'multi-select': MultiSelect,
    'vehicle-item': VehicleItem,
    'search-input': SearchInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    sideBarState: {
      type: Object as PropType<sideBarState<Vehicle>>,
      required: true,
      default: () => {
        'hide'
      },
    },
  },
  emits: ['update:sideBarState', 'addCreatedVehicle', 'updateMemberInList'],
  setup(props, context) {
    const options = ref<option[]>([
      { text: 'Nieuw', value: 'Nieuw' },
      { text: 'Uit eerdere aanvragen', value: 'Bestaand' },
    ])
    const store = useStore()
    const user = ref<ResponsibleMember>(store.getters.user)
    const { resetForm, handleSubmit, validate, meta, values, isSubmitting } = useForm<Vehicle>({ initialValues: { trailer: { id: '0', value: '0', label: 'Geen' } } })
    // @ts-ignore
    const { formSendWithSuccess } = useFormSendWithSuccess<Vehicle>(meta)
    const selected = computed(() => (props.sideBarState.state === 'list' ? 'BestaandVehicle' : 'NieuwVehicle'))
    const selectedVehicle = ref<Vehicle>({})
    const fetchedVehicles = ref<Array<Vehicle>>([])
    const generalInsuranceState = computed(() => {
      return store.state.insurance.generalInsuranceState
    })
    const loading = ref<boolean>(false)
    const { sideBarState } = toRefs(props)

    const insuranceTypeState = computed((): InsuranceTypes => {
      return store.state.insurance.insuranceTypeState
    })

    const onSubmit = async () => {
      await validate().then((validation: any) => scrollToFirstError(validation, 'addNewVehicle'))
      handleSubmit(async (values: Vehicle) => {
        if (props.sideBarState.state === 'new' || props.sideBarState.state === 'edit') {
          values.group = generalInsuranceState.value.group.id
          const vehicle = ref<Vehicle>({
            id: values.id,
            type: values.type,
            brand: values.brand,
            licensePlate: values.licensePlate,
            constructionYear: values.constructionYear,
            chassisNumber: values.chassisNumber,
            trailer: values.trailer,
            group: generalInsuranceState.value.group.id,
          })

          if (props.sideBarState.state === 'edit') {
            await updateVehicle(vehicle.value)
          } else {
            await postVehicle(vehicle.value)
          }
        }
        closeSideBar()
      })()
    }

    const addVehicle = (vehicle: any) => {
      if (vehicle) {
        selectedVehicle.value = vehicle
      }
    }

    const postVehicle = async (data: Vehicle) => {
      await RepositoryFactory.get(VehicleRepository)
        .create(data)
        .then((completed: Vehicle) => {
          selectedVehicle.value = completed
          context.emit('addCreatedVehicle', selectedVehicle.value)
          selectedVehicle.value = {}
          resetForm()
        })
    }

    const updateVehicle = async (data: Vehicle) => {
        await RepositoryFactory.get(VehicleRepository)
          .update(data.id ? data.id : '', data)
          .then((completed: Vehicle) => {
            selectedVehicle.value = completed
            context.emit('addCreatedVehicle', selectedVehicle.value)
            selectedVehicle.value = {}
            resetForm()
          })
    }

    const vehicleTypes = ref<VehicleType[]>([])

    const getVehicleTypes = () => {
      RepositoryFactory.get(VehicleTypeRepository)
        .getArray()
        .then((result: any) => {
          vehicleTypes.value = result
        })
        .catch(() => {
          RepositoryFactory.get(VehicleTypeRepository)
            .getArray()
            .then((result: any) => {
              vehicleTypes.value = result
            })
        })
    }

    const closeSideBar = () => {
      context.emit('update:sideBarState', { state: 'hide' })
    }

    const changeSideBar = (options: 'NieuwVehicle' | 'BestaandVehicle') => {
      if (options === 'NieuwVehicle') {
        context.emit('update:sideBarState', { state: 'new' })
      }

      if (options === 'BestaandVehicle') {
        context.emit('update:sideBarState', { state: 'list' })
      }
    }

    const trailers = ref<VehicleType[]>([])
    const trailersReisbijstand = ref<any>(
      [ 
        { 'id': '0', 'value': '0', 'label': 'Geen' }, 
        { 'id': '1', 'value': '1', 'label': 'Aanhangwagen (zonder meerprijs)' }
      ])

    const getTrailers = () => {
      RepositoryFactory.get(TrailerRepository)
        .getArray()
        .then((result: any) => {
          trailers.value = result
        })
        .catch(() => {
          RepositoryFactory.get(TrailerRepository)
            .getArray()
            .then((result: any) => {
              trailers.value = result
            })
        })
      }

    const fetchedOptions = (options: any) => {
      fetchedVehicles.value = []
      options.forEach((vehicle: any) => {
        fetchedVehicles.value.push(vehicle)
      })
      loading.value = false
    }

    const setVehicle = (vehicle: Vehicle) => {
      if (selected.value === 'BestaandVehicle') {
        context.emit('addCreatedVehicle', vehicle)
      }
      closeSideBar()
    }

    getVehicleTypes()
    getTrailers()

    watch(
      () => isSubmitting.value,
      () => {
        store.dispatch('setIsSubmittingState', isSubmitting.value)
      }
    )

    watch(sideBarState, (value: sideBarState<Vehicle>) => {
      if (value.state === 'edit') {
        formSendWithSuccess.value = false
        resetForm({
          values: {
            id: value.entity.id,
            type: value.entity.type,
            brand: value.entity.brand,
            licensePlate: value.entity.licensePlate,
            constructionYear: value.entity.constructionYear,
            chassisNumber: value.entity.chassisNumber,
            trailer: value.entity.trailer,
            group: generalInsuranceState.value.group.id,
          },
        })
      }

      if (value.state === 'new') {
        formSendWithSuccess.value = false
        resetForm({
          values: {
            id: '',
            type: undefined,
            brand: '',
            licensePlate: '',
            constructionYear: '',
            chassisNumber: '',
            trailer: { id: '0', value: '0', label: 'Geen' },
            group: generalInsuranceState.value.group.id,
          },
          errors: {},
        })
      }
    })

    return {
      insuranceTypeState,
      VehicleTypeRepository,
      TrailerRepository,
      VehicleRepository,
      selectedVehicle,
      fetchedVehicles,
      fetchedOptions,
      addVehicle,
      setVehicle,
      InputTypes,
      selected,
      onSubmit,
      user,
      values,
      vehicleTypes,
      trailers,
      generalInsuranceState,
      loading,
      changeSideBar,
      closeSideBar,
      options,
      trailersReisbijstand
    }
  },
})
