
import ResponsibleMemberDetail from '@/components/semantic/detail/ResponsibleMemberDetail.vue'
import { BelgianCitySearchRepository } from '@/repositories/belgianCitySearchRepository'
import { scrollToFirstError, useScrollToTop } from '@/veeValidate/helpers'
import { ClaimRepository } from '@/repositories/claims/claimRepository'
import NavigationArrow from '@/components/semantic/NavigationArrow.vue'
import { ResponsibleMember } from '@/serializer/ResponsibleMember'
import RepositoryFactory from '@/repositories/repositoryFactory'
import LabelOutput from '@/components/semantic/LabelOutput.vue'
import { ClaimHolderStates } from '@/enums/ClaimholderStates'
import CustomInput from '@/components/inputs/CustomInput.vue'
import BackButton from '@/components/semantic/BackButton.vue'
import FileRepository from '@/repositories/fileRepository'
import CustomButton from '@/components/CustomButton.vue'
import { ActivityTypes } from '@/enums/activityTypes'
import { defineComponent, computed, ref } from 'vue'
import { Claim } from '@/serializer/claims/claim'
import { DamageTypes } from '@/enums/damageTypes'
import { InputTypes } from '@/enums/inputTypes'
import { useForm } from 'vee-validate'
import { useRoute } from 'vue-router'
import { saveAs } from 'file-saver'
import { useStore } from 'vuex'
import moment from 'moment'
import FileUpload from '@/components/semantic/FileUpload.vue'
import CustomHeadline2 from '@/components/customHeadlines/CustomHeadline2.vue'
import usePermissions from '../../../helpers/usePermissions'
import PhoneNumber from '@/components/semantic/PhoneNumber.vue'
import Forbidden from '../../semantic/Forbidden.vue'

export default defineComponent({
  name: 'ClaimDetail',
  components: {
    'responsible-member-detail': ResponsibleMemberDetail,
    'navigation-arrow': NavigationArrow,
    'custom-button': CustomButton,
    'label-output': LabelOutput,
    'custom-input': CustomInput,
    'back-button': BackButton,
    FileUpload,
    CustomHeadline2,
    PhoneNumber,
    Forbidden
  },
  props: {
    isDetailPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const { scrollToTopOfPage } = useScrollToTop()
    const route = useRoute()
    const store = useStore()
    const isEdit = !!route.params.id
    const details = ref<Claim>({})
    const userData = ref<ResponsibleMember>(store.getters.user)
    const { can } = usePermissions()

    const saveFile = (file: any) => {
      saveAs(file, file.name)
    }

    const checkVisibility = () => {
      if (isEdit && !details.value.id) {
        return false
      }

      if (isForbidden.value) {
        return false 
      }

      return true
    }

    const filename = ref<string>('')
    const isForbidden = ref<boolean>(false)

    if (isEdit) {
      RepositoryFactory.get(ClaimRepository)
        .getById(route.params.id.toString())
        .then((result: any) => {
          if (result.response && result.response.status === 403) {
            isForbidden.value = true
          }
          details.value = result
          values.dossierNumber = details.value.dossierNumber
          values.note = details.value.note
          store.dispatch('setClaimState', details.value)
          if (details.value.attachment) {
            filename.value = details.value.attachment.filename
          }
        })
    }

    const { handleSubmit, values, validate, isSubmitting } = useForm<Claim>({
      initialValues: {
        victim: details.value.victim,
        activityTypes: details.value.activityTypes,
        DECLARANT_DATE: moment().format('YYYY-MM-DD'),
        declarantCity: userData.value.city,
      },
    })

    const claimState = computed((): Claim => {
      return store.state.claim.claimState
    })

    const submitting = ref<boolean>(false)

    const onSubmit = async () => {
      await validate().then((validation: any) => scrollToFirstError(validation, 'RequestInsuranceGeneral'))
      handleSubmit(async (values: any) => {
        if (!submitting.value) {
          submitting.value = true
          if (!isEdit) {
            const newClaimState = ref<Claim>({
              declarantCity: values.declarantCity ? values.declarantCity : undefined,
              DECLARANT_DATE: values.DECLARANT_DATE ? values.DECLARANT_DATE : undefined,
            })

            store.dispatch('setClaimState', { ...claimState.value, ...newClaimState.value }).then(async () => {
              await postClaim()
                .then((result: any) => {
                  store.dispatch('setClaimHolderState', ClaimHolderStates.FIVE)
                  submitting.value = false
                })
                .catch((err: Error) => {
                  window.alert(`Er is iets misgelopen: \n${err.message}`)
                })
            })
          } else {
            if (claimState.value.id) {
              await patchClaim(claimState.value.id.toString(), { note: values.note, case_number: values.dossierNumber })
              submitting.value = false
            }
          }
        }
      })()
    }

    const patchClaim = async (id: string, data: any) => {
      await RepositoryFactory.get(ClaimRepository)
        .updateInfo(id, data)
        .then((res) => {
          console.log('patched reuslt: ', res)
        })
    }

    const postClaim = async () => {
      if (values.file) {
        claimState.value.file = values.file
      }
      await RepositoryFactory.get(ClaimRepository).create(claimState.value, claimState.value.file ? claimState.value.file : undefined)
    }

    scrollToTopOfPage()

    const genderDisplay = (gender: string) => {
      if (gender === 'M') {
        return 'M'
      }
      if (gender === 'F') {
        return 'V'
      }
    }

    return {
      BelgianCitySearchRepository,
      ClaimHolderStates,
      genderDisplay,
      ActivityTypes,
      isSubmitting,
      DamageTypes,
      InputTypes,
      claimState,
      details,
      onSubmit,
      userData,
      saveFile,
      isEdit,
      values,
      moment,
      can,
      filename,
      isForbidden,
      checkVisibility
    }
  },
})
