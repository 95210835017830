import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_request_insurance_general = _resolveComponent("request-insurance-general")!
  const _component_request_insurance_type = _resolveComponent("request-insurance-type")!
  const _component_request_insurance_submit = _resolveComponent("request-insurance-submit")!
  const _component_request_insurance_detail = _resolveComponent("request-insurance-detail")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_request_insurance_general)
    ], 512), [
      [_vShow, _ctx.holderState === _ctx.HolderStates.GENERAL]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_request_insurance_type)
    ], 512), [
      [_vShow, _ctx.holderState === _ctx.HolderStates.TYPE]
    ]),
    (_ctx.holderState === _ctx.HolderStates.DETAIL)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_request_insurance_submit)
        ]))
      : _createCommentVNode("", true),
    (_ctx.holderState === _ctx.HolderStates.COMPLETED)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_request_insurance_detail)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}