import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex smo:flex-col py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bread_crumb_item = _resolveComponent("bread-crumb-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bread_crumb_item, {
      "is-change-state-possible": _ctx.holderState === _ctx.HolderStates.TYPE || _ctx.holderState === _ctx.HolderStates.DETAIL,
      "visible-on-state": _ctx.HolderStates.GENERAL,
      index: '1',
      text: 'Algemeen'
    }, null, 8, ["is-change-state-possible", "visible-on-state"]),
    _createVNode(_component_bread_crumb_item, {
      "is-change-state-possible": _ctx.holderState === _ctx.HolderStates.DETAIL,
      "visible-on-state": _ctx.HolderStates.TYPE,
      index: '2',
      text: _ctx.displayCorrectTypeLabel(_ctx.insuranceTypeState)
    }, null, 8, ["is-change-state-possible", "visible-on-state", "text"]),
    _createVNode(_component_bread_crumb_item, {
      "is-change-state-possible": false,
      "visible-on-state": _ctx.HolderStates.DETAIL,
      index: '3',
      text: 'Overzicht'
    }, null, 8, ["visible-on-state"]),
    _createVNode(_component_bread_crumb_item, {
      "is-change-state-possible": false,
      "visible-on-state": _ctx.HolderStates.COMPLETED,
      index: '4',
      text: 'Verzonden'
    }, null, 8, ["visible-on-state"])
  ]))
}