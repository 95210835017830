
import { TravelAssistanceRepository } from '@/repositories/insurances/travelAssistanceRepository'
import ResponsibleMemberDetail from '@/components/semantic/detail/ResponsibleMemberDetail.vue'
import ActivityDetail from '@/components/semantic/detail/ActivityDetail.vue'
import BaseDetail from '@/components/semantic/detail/BaseDetail.vue'
import LabelOutput from '@/components/semantic/LabelOutput.vue'
import { computed, defineComponent } from 'vue'
import { formatDate } from '@/helpers/formatHelper'
import { HolderStates } from '@/enums/holderStates'
import { InputTypes } from '@/enums/inputTypes'
import { useStore } from 'vuex'
import MemberList from '@/components/insurances/travelAssistance/memberList.vue'
import VehicleItem from '@/components/insurances/travelAssistance/vehicleItem.vue'

export default defineComponent({
  name: 'TarvelAssistanceDetail',
  components: {
    'responsible-member-detail': ResponsibleMemberDetail,
    'activity-detail': ActivityDetail,
    'label-output': LabelOutput,
    'base-detail': BaseDetail,
    'member-list': MemberList,
    'vehicle-item': VehicleItem,
  },
  props: {
    singlePage: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup() {
    const store = useStore()

    const travelAssistanceState = computed(() => {
      return store.state.insurance.travelAssistanceState
    })

    const holderState = computed((): HolderStates => {
      return store.state.insurance.holderState
    })

    return {
      TravelAssistanceRepository,
      travelAssistanceState,
      formatDate,
      InputTypes,
      HolderStates,
      holderState,
    }
  },
})
