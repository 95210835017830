import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { key: 4 }
const _hoisted_6 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_one_time_activity_detail = _resolveComponent("one-time-activity-detail")!
  const _component_non_member_insurance_detail = _resolveComponent("non-member-insurance-detail")!
  const _component_travel_assistance_detail = _resolveComponent("travel-assistance-detail")!
  const _component_temporary_vehicle_detail = _resolveComponent("temporary-vehicle-detail")!
  const _component_event_detail = _resolveComponent("event-detail")!
  const _component_material_insurance_detail = _resolveComponent("material-insurance-detail")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isCurrentType(_ctx.InsuranceTypes.EENMALIGE_ACTIVITEIT, _ctx.insuranceTypeState))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_one_time_activity_detail, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.isCurrentType(_ctx.InsuranceTypes.TIJDELIJKE_VERZEKERING_NIET_LEDEN, _ctx.insuranceTypeState))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_non_member_insurance_detail, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.isCurrentType(_ctx.InsuranceTypes.REIS_BIJSTAND, _ctx.insuranceTypeState))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_travel_assistance_detail, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.isCurrentType(_ctx.InsuranceTypes.TIJDELIJKE_AUTO_VERZEKERING, _ctx.insuranceTypeState))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_temporary_vehicle_detail, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.isCurrentType(_ctx.InsuranceTypes.EVENEMENTEN_VERZEKERING, _ctx.insuranceTypeState))
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_event_detail, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.isCurrentType(_ctx.InsuranceTypes.MATERIAAL_VERZEKERING, _ctx.insuranceTypeState))
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_material_insurance_detail, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}