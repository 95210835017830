
import { InsuranceTypeRepos, InsuranceTypes, InsuranceTypeStoreSetters } from '@/enums/insuranceTypes'
import { DraftRepository } from '@/repositories/insurances/draftRepository'
import { formatDate, formatCreatedOn, formatSingleDate } from '@/helpers/formatHelper'
import { routeDetailLinkBasedOnType } from '@/helpers/routerHelper'
import RepositoryFactory from '@/repositories/repositoryFactory'
import Loader from '@/components/semantic/Loader.vue'
import { defineComponent, PropType, ref } from 'vue'
import { Insurance } from '@/serializer/Insurance'
import { useStore } from 'vuex'
import router from '@/router'

export default defineComponent({
  name: 'CustomListItem',
  components: {
    loader: Loader,
  },
  props: {
    item: {
      type: Object as PropType<Insurance>,
      required: true,
    },
    isDraft: Boolean,
  },
  setup(props, context) {
    const store = useStore()

    const fetchInsuranceById = (id: string, type: string) => {
      setInsuranceType(type)

      //@ts-ignore
      RepositoryFactory.get(InsuranceTypeRepos[store.getters.insuranceTypeState])
        //@ts-ignore
        .getById(id)
        .then((result: any) => {
          //@ts-ignore
          store.dispatch(InsuranceTypeStoreSetters[store.getters.insuranceTypeState], result).then(() => {
            router.push('/verzekering-bewerken/' + id)
          })
        })
    }

    const goToDraft = (id: string, type: string) => {
      setInsuranceType(type)

      //@ts-ignore
      RepositoryFactory.get(InsuranceTypeRepos[store.getters.insuranceTypeState])
        //@ts-ignore
        .getDraftById(id)
        .then((result: any) => {
          //@ts-ignore
          store.dispatch(InsuranceTypeStoreSetters[store.getters.insuranceTypeState], result).then(() => {
            router.push('/draft-bewerken/' + id)
          })
        })
    }

    const isDeletingDraft = ref<boolean>(false)

    const deleteDraft = (id: string) => {
      if (isDeletingDraft.value === false) {
        isDeletingDraft.value = true
        RepositoryFactory.get(DraftRepository)
          .removeById(id)
          .then(() => {
            context.emit('removeDraft', id)
            isDeletingDraft.value = false
          })
      }
    }

    const setInsuranceType = (type: string) => {
      switch (type) {
        case 'TypeEenmaligeActiviteit':
          setInsuranceTypeState(InsuranceTypes.EENMALIGE_ACTIVITEIT)
          break

        case 'TypeTijdelijkeVerzekering':
          setInsuranceTypeState(InsuranceTypes.TIJDELIJKE_VERZEKERING_NIET_LEDEN)
          break

        case 'TypeEthiasAssistanceMetAuto':
          setInsuranceTypeState(InsuranceTypes.REIS_BIJSTAND)
          break

        case 'TypeEthiasAssistanceZonderAuto':
          setInsuranceTypeState(InsuranceTypes.REIS_BIJSTAND)
          break

        case 'TypeTijdelijkeAutoverzekering':
          setInsuranceTypeState(InsuranceTypes.TIJDELIJKE_AUTO_VERZEKERING)
          break

        case 'TypeEvenementenVerzekering':
          setInsuranceTypeState(InsuranceTypes.EVENEMENTEN_VERZEKERING)
          break

        case 'TypeGroepsmateriaalVerzekering':
          setInsuranceTypeState(InsuranceTypes.MATERIAAL_VERZEKERING)
          break

        default:
          break
      }
    }
    const setInsuranceTypeState = (value: string) => {
      store.dispatch('setInsuranceTypeState', value)
    }
    return {
      routeDetailLinkBasedOnType,
      fetchInsuranceById,
      formatSingleDate,
      isDeletingDraft,
      formatCreatedOn,
      deleteDraft,
      formatDate,
      goToDraft,
    }
  },
})
