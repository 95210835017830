
import { InsuranceTypes } from '@/enums/insuranceTypes'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'InsuranceTypeMenu',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore()
    const selection = ref<string>(store.getters.insuranceTypeState)
    const insuranceTypes = ref<Object[]>([
      { name: 'Tijdelijke verzekering niet-leden', value: InsuranceTypes.TIJDELIJKE_VERZEKERING_NIET_LEDEN },
      { name: 'Tijdelijke autoverzekering', value: InsuranceTypes.TIJDELIJKE_AUTO_VERZEKERING },
      { name: 'Materiaalverzekering', value: InsuranceTypes.MATERIAAL_VERZEKERING },
      { name: 'Evenementenverzekering', value: InsuranceTypes.EVENEMENTEN_VERZEKERING },
      { name: 'Verzekering eenmalige activiteit', value: InsuranceTypes.EENMALIGE_ACTIVITEIT },
      { name: 'Reisbijstand', value: InsuranceTypes.REIS_BIJSTAND },
    ])
    const setInsuranceTypeState = (value: string) => {
      store.dispatch('setInsuranceTypeState', value)
    }

    return {
      setInsuranceTypeState,
      insuranceTypes,
      selection,
    }
  },
})
