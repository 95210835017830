import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "mt-3" }
const _hoisted_3 = { class: "flex gap-1" }
const _hoisted_4 = { class: "mt-3" }
const _hoisted_5 = { class: "flex gap-1" }
const _hoisted_6 = { class: "md:ml-20 xs:ml-5 sm:ml-5" }
const _hoisted_7 = { class: "md:ml-20 xs:ml-5 sm:ml-5" }
const _hoisted_8 = { class: "flex gap-3 md:ml-20 xs:ml-5 sm:ml-5 mt-5 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_headline_2 = _resolveComponent("custom-headline-2")!
  const _component_required = _resolveComponent("required")!
  const _component_select_drivers = _resolveComponent("select-drivers")!
  const _component_select_vehicle = _resolveComponent("select-vehicle")!
  const _component_custom_input = _resolveComponent("custom-input")!
  const _component_back_button = _resolveComponent("back-button")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock("form", {
    id: "TemporaryVehicle",
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
  }, [
    (_ctx.values)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_custom_headline_2, { text: "Bestuurders" }),
              _createVNode(_component_required, {
                rules: "required",
                class: "mt-3"
              })
            ]),
            _createVNode(_component_select_drivers, {
              id: "selectDriverField",
              rules: "RequiredDrivers:selectDriverField"
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_custom_headline_2, { text: "Voertuig" }),
              _createVNode(_component_required, {
                rules: "required",
                class: "mt-3"
              })
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_select_vehicle, {
                id: "vehicle",
                rules: "required|RequiredVehicle:vehicle"
              })
            ])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_custom_headline_2, { text: "Opmerkingen" }),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_custom_input, {
                type: _ctx.InputTypes.TEXT_AREA,
                name: "comment",
                label: ""
              }, null, 8, ["type"])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_back_button, {
              stateName: "setHolderState",
              backToState: _ctx.HolderStates.GENERAL
            }, null, 8, ["backToState"]),
            _createVNode(_component_custom_button, { text: "Volgende" }),
            (!_ctx.isEdit)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: "link-inline cursor-pointer",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveAsDraft()))
                }, "Opslaan als concept"))
              : _createCommentVNode("", true),
            (_ctx.isDraftEdit)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  class: "link-inline cursor-pointer",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.patchDraft()))
                }, "Opslaan als concept"))
              : _createCommentVNode("", true),
            _createVNode(_component_loader, { "is-loading": _ctx.isSavingDraft }, null, 8, ["is-loading"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 32))
}