
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SuccessToast',
  props: {
    label: {
      type: String,
      required: false,
      default: 'Succesvol toegevoegd',
    },
  },
  emits: ['update:showOrHide'],
  setup(props, { emit }) {
    const hideToast = () => {
      emit('update:showOrHide', false)
    }

    return {
      hideToast,
    }
  },
})
