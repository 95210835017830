
import { InsuranceTypeRepos, InsuranceTypeStoreSetters } from '@/enums/insuranceTypes'
import { DraftRepository } from '@/repositories/insurances/draftRepository'
import CallToAction from '@/components/customHeadlines/CallToAction.vue'
import RequestInsuranceDetail from './RequestInsuranceDetail.vue'
import RepositoryFactory from '@/repositories/repositoryFactory'
import CustomInput from '@/components/inputs/CustomInput.vue'
import BackButton from '@/components/semantic/BackButton.vue'
import CustomButton from '@/components/CustomButton.vue'
import { HolderStates } from '@/enums/holderStates'
import { defineComponent, ref, watch } from 'vue'
import { InputTypes } from '@/enums/inputTypes'
import { useForm } from 'vee-validate'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'RequestInsuranceSubmit',
  components: {
    'request-insurance-detail': RequestInsuranceDetail,
    'call-to-action': CallToAction,
    'custom-button': CustomButton,
    'custom-input': CustomInput,
    'back-button': BackButton,
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const error = ref<boolean>(false)
    const isEdit = !!route.params.id
    const isDraft = route.meta.isDraft ? route.meta.isDraft : false

    const data: any = store.getters.getCurrentInsuranceState
    const { handleSubmit, isSubmitting } = useForm({
      initialValues: {
        comment: data.comment ? data.comment : '',
      },
    })

    const onSubmit = handleSubmit(async (values: any) => {
      //@ts-ignore
      store.dispatch(InsuranceTypeStoreSetters[store.getters.insuranceTypeState], { ...store.getters.getCurrentInsuranceState, ...{ comment: values.comment } })
      if (isEdit && !isDraft) {
        await editInsurance()
      } else {
        await postInsurance()
      }
    })

    const postInsurance = async () => {
      //@ts-ignore
      await RepositoryFactory.get(InsuranceTypeRepos[store.getters.insuranceTypeState])
        //@ts-ignore
        .create(store.getters.getCurrentInsuranceState)
        .then((completed: any) => {
          store.dispatch('setHolderState', HolderStates.COMPLETED)
          //@ts-ignore
          store.dispatch(InsuranceTypeStoreSetters[store.getters.insuranceTypeState], completed)

          //IF POSTED FROM A DRAFT, DRAFT WILL BE DELETED
          if (isEdit) {
            RepositoryFactory.get(DraftRepository).removeById(route.params.id)
          }
        })
        .catch((error: any) => {
          error.value = true
        })
    }

    const editInsurance = async () => {
      // @ts-ignore
      await RepositoryFactory.get(InsuranceTypeRepos[store.getters.insuranceTypeState])
        //@ts-ignore
        .editById(route.params.id, store.getters.getCurrentInsuranceState)
        .then((completed: any) => {
          store.dispatch('setHolderState', HolderStates.COMPLETED)
          //@ts-ignore
          store.dispatch(InsuranceTypeStoreSetters[store.getters.insuranceTypeState], completed)
        })
        .catch(() => {
          error.value = true
        })
    }

    const back = () => {
      store.dispatch('setHolderState', HolderStates.TYPE)
    }

    watch(
      () => isSubmitting.value,
      () => {
        store.dispatch('setIsSubmittingState', isSubmitting.value)
      }
    )

    return {
      HolderStates,
      isSubmitting,
      InputTypes,
      onSubmit,
      error,
      back,
      data,
    }
  },
})
