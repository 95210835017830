import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1935f192"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-col overflow-y-scroll overflow-x-hidden px-4 h-full" }
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = { class: "w-100 mt-4" }
const _hoisted_4 = { class: "w-100" }
const _hoisted_5 = { class: "w-100 mt-4" }
const _hoisted_6 = { class: "w-100 mt-4" }
const _hoisted_7 = { class: "w-100 mt-4" }
const _hoisted_8 = { class: "w-100 mt-4" }
const _hoisted_9 = { class: "w-100 mt-4" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "py-4 sticky bottom-0 bg-white pl-4" }
const _hoisted_12 = { class: "h-full overflow-y-scroll mt-4 pb-36" }
const _hoisted_13 = {
  key: 0,
  class: "mt-4 border-t-2 w-100 border-black"
}
const _hoisted_14 = { class: "pt-3 pb-4 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_success_toast = _resolveComponent("success-toast")!
  const _component_custom_input = _resolveComponent("custom-input")!
  const _component_multi_select = _resolveComponent("multi-select")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_search_input = _resolveComponent("search-input")!
  const _component_non_member_item = _resolveComponent("non-member-item")!
  const _component_base_side_bar = _resolveComponent("base-side-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_base_side_bar, {
      isOverflowHidden: _ctx.isOverflowHidden,
      selection: _ctx.selected,
      "is-display": _ctx.sideBarState.state !== 'hide',
      "is-edit": _ctx.sideBarState.state === 'edit',
      name: "NonMember",
      title: _ctx.title,
      options: _ctx.options,
      onOptions: _ctx.changeSideBar,
      onHideSidebar: _ctx.closeSideBar
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          id: "addNewNonMember",
          ref: "formDiv",
          class: _normalizeClass([{ 'd-flex': _ctx.sideBarState.state === 'new' || _ctx.sideBarState.state === 'edit', 'd-none': _ctx.sideBarState.state === 'list' }, "flex-col relative overflow-y-scroll overflow-x-hidden h-full"]),
          onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
        }, [
          _withDirectives(_createVNode(_component_success_toast, { label: "Persoon succesvol toegevoegd" }, null, 512), [
            [_vShow, _ctx.formSendWithSuccess]
          ]),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_custom_input, {
                "loading-submit": _ctx.isSubmitting,
                type: _ctx.InputTypes.TEXT,
                rules: "required",
                name: "firstName",
                label: "Voornaam"
              }, null, 8, ["loading-submit", "type"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_custom_input, {
                "loading-submit": _ctx.isSubmitting,
                type: _ctx.InputTypes.TEXT,
                rules: "required",
                name: "lastName",
                label: "Achternaam"
              }, null, 8, ["loading-submit", "type"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_multi_select, {
                id: "postCodeCity",
                "loading-submit": _ctx.isSubmitting,
                object: true,
                "track-by": "label",
                "value-prop": "label",
                repository: _ctx.BelgianCitySearchRepository,
                "resolve-on-load": true,
                options: [],
                searchable: true,
                label: "Gemeente",
                rules: "required",
                placeholder: "Zoek op naam/postcode"
              }, null, 8, ["loading-submit", "repository"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_custom_input, {
                "loading-submit": _ctx.isSubmitting,
                type: _ctx.InputTypes.TEXT,
                rules: "required",
                name: "street",
                label: "Straat"
              }, null, 8, ["loading-submit", "type"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_custom_input, {
                "loading-submit": _ctx.isSubmitting,
                type: _ctx.InputTypes.TEXT,
                maxlength: "5",
                rules: "required",
                name: "number",
                label: "Nr"
              }, null, 8, ["loading-submit", "type"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_custom_input, {
                "loading-submit": _ctx.isSubmitting,
                type: _ctx.InputTypes.TEXT,
                maxlength: "5",
                name: "letterBox",
                label: "Bus"
              }, null, 8, ["loading-submit", "type"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_custom_input, {
                "loading-submit": _ctx.isSubmitting,
                type: _ctx.InputTypes.DATE,
                name: "birthDate",
                label: "Geboortedatum",
                rules: "required"
              }, null, 8, ["loading-submit", "type"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_custom_input, {
                "loading-submit": _ctx.isSubmitting,
                type: _ctx.InputTypes.TEXT_AREA,
                name: "comment",
                label: "Opmerking"
              }, {
                default: _withCtx(() => [
                  (_ctx.isExtraInformationComment)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_10, " Indien je niet-leden wil verzekeren die in het buitenland wonen moet je in dit tekstvak de buitenlands gemeente, postcode en land opgeven. En bij adres, het belgisch adres van de persoon die de verzekering aanvraagt. "))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["loading-submit", "type"])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_custom_button, {
              "loading-submit": _ctx.isSubmitting,
              text: _ctx.sideBarState.state === 'edit' ? 'Bewerk' : 'Voeg toe'
            }, null, 8, ["loading-submit", "text"])
          ])
        ], 34),
        _createElementVNode("form", {
          class: _normalizeClass([{ 'd-flex': _ctx.sideBarState.state === 'list', 'd-none': _ctx.sideBarState.state === 'new' || _ctx.sideBarState.state === 'edit' }, "flex-col h-full px-4 pt-3"]),
          onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", null, [
            (_ctx.generalInsuranceState.group)
              ? (_openBlock(), _createBlock(_component_search_input, {
                  key: 0,
                  start: _ctx.generalInsuranceState.startDate,
                  end: _ctx.generalInsuranceState.endDate,
                  group: _ctx.generalInsuranceState.group.id,
                  loading: _ctx.loading,
                  "onUpdate:loading": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loading) = $event)),
                  name: "nonMember",
                  placeholder: "Zoek op naam",
                  repository: _ctx.NonMemberRepository,
                  onFetchedOptions: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchedOptions($event)))
                }, null, 8, ["start", "end", "group", "loading", "repository"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_12, [
            (_ctx.selectedNonMembers.length > 0)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_13))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedNonMembers, (nonMember) => {
              return (_openBlock(), _createElementBlock("div", {
                key: nonMember.id,
                class: "w-100"
              }, [
                _createVNode(_component_non_member_item, { "non-member": nonMember }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_custom_button, {
                          type: "button",
                          text: _ctx.existingList.includes(nonMember) ? 'Toegevoegd' : 'Voeg toe',
                          onClick: ($event: any) => (_ctx.addNonMember(nonMember))
                        }, null, 8, ["text", "onClick"])
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["non-member"])
              ]))
            }), 128))
          ])
        ], 34)
      ]),
      _: 1
    }, 8, ["isOverflowHidden", "selection", "is-display", "is-edit", "title", "options", "onOptions", "onHideSidebar"])
  ]))
}