import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full shadow-md border-b-2 border-black bg-white p-2 h-full inline-block text-left d-flex flex-col justify-content-between"
}
const _hoisted_2 = { class: "py-3" }
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_phone_number = _resolveComponent("phone-number")!

  return (_ctx.member)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.member.firstName + ' ' + _ctx.member.lastName), 1)
            ]),
            _createElementVNode("div", null, [
              _renderSlot(_ctx.$slots, "deleteSection")
            ])
          ]),
          (_ctx.member.email)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("p", null, _toDisplayString(_ctx.member.email !== '' ? _ctx.member.email : '/'), 1)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_phone_number, {
            phoneNumber: _ctx.member.phoneNumber
          }, null, 8, ["phoneNumber"]),
          (_ctx.member.postCodeCity && _ctx.member.street)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("p", null, _toDisplayString(_ctx.member.street + ' ' + _ctx.member.number + (_ctx.member.letterBox ? ' Bus ' + _ctx.member.letterBox : '') + ', ' + _ctx.member.postCodeCity.postalCode + ' ' + _ctx.member.postCodeCity.city), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _createElementVNode("p", null, _toDisplayString(_ctx.member.birthDate), 1)
          ])
        ]),
        _renderSlot(_ctx.$slots, "default")
      ]))
    : _createCommentVNode("", true)
}