import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d90d7f9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-col overflow-y-scroll overflow-x-hidden px-4 h-full" }
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = { class: "w-100 mt-4 w-" }
const _hoisted_4 = { class: "w-100 mt-4" }
const _hoisted_5 = { class: "w-100 mt-4" }
const _hoisted_6 = { class: "w-100" }
const _hoisted_7 = {
  key: 0,
  class: "w-100 mb-32"
}
const _hoisted_8 = {
  key: 1,
  class: "w-100 mb-32"
}
const _hoisted_9 = { class: "ml-4 py-4 sticky bottom-0 bg-white" }
const _hoisted_10 = { class: "h-full overflow-y-scroll mt-4 pb-44" }
const _hoisted_11 = {
  key: 0,
  class: "mt-4 border-t-2 w-100 border-black pb-4"
}
const _hoisted_12 = { class: "pb-4 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_input = _resolveComponent("custom-input")!
  const _component_multi_select = _resolveComponent("multi-select")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_search_input = _resolveComponent("search-input")!
  const _component_vehicle_item = _resolveComponent("vehicle-item")!
  const _component_base_side_bar = _resolveComponent("base-side-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_base_side_bar, {
      selection: _ctx.selected,
      "is-display": _ctx.sideBarState.state !== 'hide',
      name: "Vehicle",
      title: _ctx.title,
      options: _ctx.options,
      onOptions: _ctx.changeSideBar,
      onHideSidebar: _ctx.closeSideBar
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          id: "addNewVehicle",
          class: _normalizeClass([{ 'd-flex': _ctx.sideBarState.state === 'new' || _ctx.sideBarState.state === 'edit', 'd-none': _ctx.sideBarState.state === 'list' }, "flex-col relative overflow-y-scroll overflow-x-hidden h-full"]),
          onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_custom_input, {
                type: _ctx.InputTypes.TEXT,
                rules: "required",
                name: "brand",
                label: "Merk"
              }, null, 8, ["type"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_custom_input, {
                maxlength: 10,
                type: _ctx.InputTypes.TEXT,
                rules: "required",
                name: "licensePlate",
                label: "Nummerplaat"
              }, null, 8, ["type"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_custom_input, {
                type: _ctx.InputTypes.TEXT,
                rules: "required",
                name: "constructionYear",
                maxlength: "4",
                label: "Bouwjaar"
              }, null, 8, ["type"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_custom_input, {
                maxlength: 20,
                type: _ctx.InputTypes.TEXT,
                rules: "required",
                name: "chassisNumber",
                label: "Chassisnummer"
              }, null, 8, ["type"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_multi_select, {
                id: "type",
                class: "custom",
                object: true,
                "track-by": "label",
                "value-prop": "value",
                repository: _ctx.VehicleTypeRepository,
                options: _ctx.vehicleTypes,
                label: "Type",
                rules: "required",
                placeholder: "Selecteer type"
              }, null, 8, ["repository", "options"])
            ]),
            (_ctx.insuranceTypeState !== 'REIS_BIJSTAND')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_multi_select, {
                    id: "trailer",
                    object: true,
                    "track-by": "label",
                    "value-prop": "value",
                    repository: _ctx.TrailerRepository,
                    options: _ctx.trailers,
                    label: "Aanhangwagen",
                    rules: "required",
                    placeholder: "Selecteer aanhangwagen"
                  }, null, 8, ["repository", "options"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_multi_select, {
                    id: "trailer",
                    object: true,
                    "track-by": "label",
                    "value-prop": "value",
                    repository: _ctx.TrailerRepository,
                    options: _ctx.trailersReisbijstand,
                    label: "Aanhangwagen",
                    rules: "required",
                    placeholder: "Selecteer aanhangwagen"
                  }, null, 8, ["repository", "options"])
                ]))
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_custom_button, {
              text: _ctx.sideBarState.state === 'edit' ? 'Bewerk' : 'Voeg toe'
            }, null, 8, ["text"])
          ])
        ], 34),
        (_ctx.generalInsuranceState && _ctx.generalInsuranceState.group)
          ? (_openBlock(), _createElementBlock("form", {
              key: 0,
              class: _normalizeClass([{ 'd-flex': _ctx.sideBarState.state === 'list', 'd-none': _ctx.sideBarState.state === 'new' || _ctx.sideBarState.state === 'edit' }, "flex-col h-full px-4 pt-3"]),
              onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
            }, [
              _createElementVNode("div", null, [
                _createVNode(_component_search_input, {
                  loading: _ctx.loading,
                  "onUpdate:loading": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loading) = $event)),
                  name: "search",
                  placeholder: "Zoek op merk",
                  repository: _ctx.VehicleRepository,
                  onFetchedOptions: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchedOptions($event))),
                  group: _ctx.generalInsuranceState.group.id
                }, null, 8, ["loading", "repository", "group"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                (_ctx.fetchedVehicles.length > 0)
                  ? (_openBlock(), _createElementBlock("hr", _hoisted_11))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fetchedVehicles, (vehicle) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: vehicle.id,
                    class: "w-100"
                  }, [
                    _createVNode(_component_vehicle_item, { vehicle: vehicle }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", null, [
                              _createVNode(_component_custom_button, {
                                type: "button",
                                text: "Kies",
                                onClick: ($event: any) => (_ctx.setVehicle(vehicle))
                              }, null, 8, ["onClick"])
                            ])
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["vehicle"])
                  ]))
                }), 128))
              ])
            ], 34))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["selection", "is-display", "title", "options", "onOptions", "onHideSidebar"])
  ]))
}