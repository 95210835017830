
import { NonMemberInsuranceRepository } from '@/repositories/insurances/nonMemberInsuranceRepository'

import ResponsibleMemberDetail from '@/components/semantic/detail/ResponsibleMemberDetail.vue'
import ActivityDetail from '@/components/semantic/detail/ActivityDetail.vue'
import BaseDetail from '@/components/semantic/detail/BaseDetail.vue'
import LabelOutput from '@/components/semantic/LabelOutput.vue'
import { computed, defineComponent } from 'vue'
import { formatDate } from '@/helpers/formatHelper'
import { HolderStates } from '@/enums/holderStates'
import { InputTypes } from '@/enums/inputTypes'
import { useStore } from 'vuex'
import NonMembersList from '@/components/insurances/nonMembersInsurance/nonMembersList.vue'

export default defineComponent({
  name: 'NonMemberInsuranceDetail',
  components: {
    'responsible-member-detail': ResponsibleMemberDetail,
    'activity-detail': ActivityDetail,
    'label-output': LabelOutput,
    'base-detail': BaseDetail,
    'non-members-list': NonMembersList,
  },
  props: {
    singlePage: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup() {
    const store = useStore()

    const nonMemberState = computed(() => {
      return store.state.insurance.nonMemberState
    })

    const holderState = computed((): HolderStates => {
      return store.state.insurance.holderState
    })

    return {
      NonMemberInsuranceRepository,
      nonMemberState,
      formatDate,
      InputTypes,
      HolderStates,
      holderState,
    }
  },
})
