import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "flex",
  style: {"margin-right":"1em"}
}
const _hoisted_2 = {
  key: 3,
  class: "pt-2 pl-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isChangeStatePossible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['h-10 w-10 text-center p-2 rounded-full border-2 border-lightGray cursor-pointer', _ctx.visibleOnState === _ctx.claimHolderState ? 'bg-lightGreen' : '']),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeState()))
        }, _toDisplayString(_ctx.index), 3))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(['h-10 w-10 text-center p-2 rounded-full border-2 border-lightGray', _ctx.visibleOnState === _ctx.claimHolderState ? 'bg-lightGreen' : ''])
        }, _toDisplayString(_ctx.index), 3)),
    (_ctx.isChangeStatePossible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "pt-2 pl-3 cursor-pointer",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeState()))
        }, _toDisplayString(_ctx.text), 1))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.text), 1))
  ]))
}