
import OneTimeActivity from '@/components/insurances/oneTimeActivityInsurance/oneTimeActivity.vue'
import TravelAssistance from '@/components/insurances/travelAssistance/travelAssistance.vue'
import NonMember from '@/components/insurances/nonMembersInsurance/nonMember.vue'
import MaterialInsurance from '@/components/insurances/materialInsurance/materialInsurance.vue'
import TemporaryVehicle from '@/components/insurances/tempCarInsurance/temporaryVehicle.vue'
import EventInsurance from '@/components/insurances/eventInsurance/eventInsurance.vue'
import { isCurrentType } from '@/helpers/insuranceTypeHelper'
import { InsuranceTypes } from '@/enums/insuranceTypes'
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'RequestInsuranceType',
  components: {
    'one-time-activity': OneTimeActivity,
    'travel-assistance': TravelAssistance,
    'temporary-vehicle': TemporaryVehicle,
    'event-insurance': EventInsurance,
    'material-insurance': MaterialInsurance,
    'non-member': NonMember,
  },
  setup() {
    const store = useStore()

    const insuranceTypeState = computed((): InsuranceTypes => {
      return store.state.insurance.insuranceTypeState
    })

    return {
      insuranceTypeState,
      InsuranceTypes,
      isCurrentType,
    }
  },
})
