
import { Vehicle } from '@/serializer/Vehicle'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    vehicle: {
      type: Object as PropType<Vehicle>,
      required: true,
    },
    noLine: Boolean,
  },
})
