import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-semibold mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_phone_number = _resolveComponent("phone-number")!
  const _component_label_output = _resolveComponent("label-output")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.title === 'Aanvrager' ? 'md:ml-20 xs:ml-5 sm:ml-5' : '')
    }, [
      _createElementVNode("div", null, [
        _createElementVNode("p", null, _toDisplayString(_ctx.responsibleMember.firstName + ' ' + (_ctx.responsibleMember.lastName ? _ctx.responsibleMember.lastName : '')), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("p", null, _toDisplayString(_ctx.responsibleMember.email), 1)
      ]),
      _createVNode(_component_phone_number, {
        phoneNumber: _ctx.responsibleMember.phoneNumber
      }, null, 8, ["phoneNumber"]),
      (_ctx.responsibleMember.membershipNumber)
        ? (_openBlock(), _createBlock(_component_label_output, {
            key: 0,
            label: "Lidnummer",
            text: _ctx.responsibleMember.membershipNumber
          }, null, 8, ["text"]))
        : _createCommentVNode("", true)
    ], 2)
  ], 64))
}