
import { HolderStates } from '@/enums/holderStates'
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'BreadCrumbItem',
  props: {
    index: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    visibleOnState: {
      type: String,
      required: true,
    },
    isChangeStatePossible: {
      type: Boolean,
      Required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const holderState = computed((): HolderStates => {
      return store.state.insurance.holderState
    })
    const changeState = () => {
      if (props.isChangeStatePossible) {
        store.dispatch('setHolderState', props.visibleOnState)
      }
    }
    return {
      changeState,
      holderState,
    }
  },
})
