
import RequestBreadCrumb from '@/components/requestClaim/requestBreadCrumb/RequestBreadCrumbClaim.vue'
import StateClaimFormHolder from '@/components/semantic/HolderStateClaimForm.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RequestClaim',
  components: {
    'request-bread-crumb': RequestBreadCrumb,
    'state-claim-form-holder': StateClaimFormHolder,
  },
  setup() {
    return {}
  },
})
