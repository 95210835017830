import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "py-2" }
const _hoisted_2 = {
  key: 0,
  class: "py-2"
}
const _hoisted_3 = {
  key: 1,
  class: "py-2"
}
const _hoisted_4 = {
  key: 2,
  class: "py-2"
}
const _hoisted_5 = {
  key: 3,
  class: "py-2"
}
const _hoisted_6 = {
  key: 4,
  class: "py-2"
}
const _hoisted_7 = {
  key: 5,
  class: "mt-2"
}
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_choice_help_item = _resolveComponent("choice-help-item")!
  const _component_info_alert = _resolveComponent("info-alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_choice_help_item, {
        id: "0",
        text: "Heb je de auto gehuurd?",
        choice: _ctx.choices[0],
        "onUpdate:choice": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.choices[0]) = $event))
      }, null, 8, ["choice"])
    ]),
    (_ctx.choices[0] === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_choice_help_item, {
            id: "1",
            text: "Is er een vrijstelling (franchise) op de wettelijk verplichte verzekering voor burgerlijke aansprakelijkheid (BA)?",
            choice: _ctx.choices[1],
            "onUpdate:choice": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.choices[1]) = $event))
          }, null, 8, ["choice"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.choices[1] === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_choice_help_item, {
            id: "2",
            text: "Wil je de vrijstelling van de verzekering voor burgerlijke aansprakelijkheid afkopen?",
            choice: _ctx.choices[2],
            "onUpdate:choice": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.choices[2]) = $event))
          }, null, 8, ["choice"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.choices[2] === true || _ctx.choices[0] === false || (_ctx.choices[0] === true && _ctx.choices[1] === false) || (_ctx.choices[0] === true && _ctx.choices[1] === true && _ctx.choices[2] === false))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_choice_help_item, {
            id: "3",
            text: "Is de auto omnium verzekerd?",
            choice: _ctx.choices[3],
            "onUpdate:choice": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.choices[3]) = $event))
          }, null, 8, ["choice"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.choices[3] === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_choice_help_item, {
            id: "4",
            text: "Wil je de vrijstelling (franchise) van deze verzekering afkopen?",
            choice: _ctx.choices[4],
            "onUpdate:choice": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.choices[4]) = $event))
          }, null, 8, ["choice"])
        ]))
      : _createCommentVNode("", true),
    (
      (_ctx.choices[0] === false && _ctx.choices[3] === false) ||
      (_ctx.choices[0] === true && _ctx.choices[1] === false && _ctx.choices[3] === false) ||
      (_ctx.choices[0] === true && _ctx.choices[1] === true && _ctx.choices[2] === false && _ctx.choices[3] === false) ||
      (_ctx.choices[0] === true && _ctx.choices[1] === true && _ctx.choices[2] === true && _ctx.choices[3] === false)
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_choice_help_item, {
            id: "5",
            text: "Wil je een omnium verzekering afsluiten?",
            choice: _ctx.choices[5],
            "onUpdate:choice": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.choices[5]) = $event))
          }, null, 8, ["choice"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.displayText() !== '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_info_alert, null, {
            default: _withCtx(() => [
              _createElementVNode("p", {
                innerHTML: _ctx.displayText()
              }, null, 8, _hoisted_8)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}