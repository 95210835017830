
import { BelgianCitySearchRepository } from '@/repositories/belgianCitySearchRepository'
import MemberItem from '@/components/insurances/travelAssistance/memberItem.vue'
import { MemberRepository } from '@/repositories/memberRepository'
import { PersonRepository } from '@/repositories/personRepository'
import { ResponsibleMember } from '@/serializer/ResponsibleMember'
import RepositoryFactory from '@/repositories/repositoryFactory'
import SearchInput from '@/components/inputs/SearchInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import { defineComponent, ref, watch } from 'vue'
import { InputTypes } from '@/enums/inputTypes'
import { Member } from '@/serializer/Member'
import { useStore } from 'vuex'
import { NonMemberRepository } from '@/repositories/nonMemberRepository'
import { BaseSideBar } from 'vue-3-component-library'

export default defineComponent({
  name: 'MemberSideBar',
  components: {
    'custom-button': CustomButton,
    'base-side-bar': BaseSideBar,
    'search-input': SearchInput,
    'member-item': MemberItem,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    isDisplay: {
      type: Boolean,
      required: true,
    },
    existingList: {
      type: Array,
      default: () => {
        return []
      },
    },
    closeOnAdd: {
      type: Boolean,
      default: false,
      required: false,
    },
    isOverflowHidden: {
      type: Boolean,
      required: false,
      default: true,
    },
    group: String,
    start: String,
    end: String,
  },
  setup(props, context) {
    const store = useStore()
    const user = ref<ResponsibleMember>(store.getters.user)
    const display = ref<boolean>(props.isDisplay)
    const selectedMembers = ref<Member[]>([])
    const loading = ref<boolean>(false)

    const addMember = (member: Member) => {
      if (!props.existingList.includes(member)) {
        context.emit('addMemberToList', member)
      }
      if (props.closeOnAdd) {
        display.value = false
      }
    }

    const fetchedOptions = (options: any) => {
      selectedMembers.value = []
      options.forEach((member: Member) => {
        // if (member.groupAdminId) {
        //   if (member.isMember) {
        //     RepositoryFactory.get(MemberRepository)
        //       .getById(member.groupAdminId)
        //       .then((result: Member) => {
        //         result.birthDate = member.birthDate
        //         result.gender = member.gender
        //         result.isMember = true
        //         selectedMembers.value.push(result)
        //       })
        //   }
        // }
        // if (member.id && !member.isMember) {
        //   RepositoryFactory.get(NonMemberRepository)
        //     .getById(member.id)
        //     .then((result: Member) => {
        //       result.birthDate = member.birthDate
        //       result.isMember = false
        //       result.gender = member.gender
        //       selectedMembers.value.push(result)
        //     })
        // }
        selectedMembers.value.push(member)
      })
      loading.value = false
    }

    watch(
      () => props.isDisplay,
      () => {
        display.value = props.isDisplay
      }
    )

    watch(
      () => display.value,
      () => {
        context.emit('update:isDisplay', display.value)
      }
    )

    return {
      BelgianCitySearchRepository,
      MemberRepository,
      PersonRepository,
      selectedMembers,
      fetchedOptions,
      InputTypes,
      addMember,
      display,
      loading,
      user,
    }
  },
})
