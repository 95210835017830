
import CallToAction from '../components/customHeadlines/CallToAction.vue'
import { ClaimRepository } from '@/repositories/claims/claimRepository'
import RepositoryFactory from '@/repositories/repositoryFactory'
import ClaimList from '@/components/semantic/ClaimList.vue'
import { ArrayResult } from '@/serializer/ArrayResult'
import { Loader } from 'vue-3-component-library'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { Group } from '@/serializer/Group'
// import MultiSelect from '@/components/inputs/MultiSelect.vue'
import { ResponsibleMember } from '@/serializer/ResponsibleMember'
import SearchInputClaims from '@/components/inputs/SearchInputClaims.vue'
import usePermissions from '../helpers/usePermissions'

export default defineComponent({
  name: 'ClaimsHome',
  components: {
    'call-to-action': CallToAction,
    'claim-list': ClaimList,
    loader: Loader,
    // 'multi-select': MultiSelect,
    'search-input-claims': SearchInputClaims,
  },
  setup() {
    const { can } = usePermissions()
    const loading = ref<boolean>(false)
    const isLoading = ref<boolean>(false)
    const store = useStore()
    const userData = ref<ResponsibleMember>(store.getters.user)
    store.dispatch('resetStates')
    const data = ref<ArrayResult>()
    const getClaims = () => {
      RepositoryFactory.get(ClaimRepository)
        .getArray('/insurances_claims/?page=1&page_size=10')
        .then((res: ArrayResult) => {
          data.value = res
        })
    }

    const getPreviousClaims = (previous: string) => {
      isLoading.value = true
      RepositoryFactory.get(ClaimRepository)
        .getArray(previous)
        .then((res: ArrayResult) => {
          data.value = res
          isLoading.value = false
        })
    }

    const getNextClaims = (next: string) => {
      isLoading.value = true
      RepositoryFactory.get(ClaimRepository)
        .getArray(next)
        .then((res: ArrayResult) => {
          data.value = res
          isLoading.value = false
        })
    }

    const selection = ref<Group>({id: ''})

    const addSelectionInsurances = (group: Group) => {
      selection.value.id = group.id
      RepositoryFactory.get(ClaimRepository)
        .getArray('/insurances_claims/?group_number=' + selection.value.id + '&search=&page=1&page_size=10')
        .then((res: ArrayResult) => {
          data.value = res
        })
    }

    const fetchedOptions = (options: any) => {
      data.value = options
      loading.value = false
    }
    
    getClaims()

    return {
      data,
      getPreviousClaims,
      getNextClaims,
      isLoading,
      addSelectionInsurances,
      userData,
      loading,
      fetchedOptions,
      ClaimRepository,
      can
    }
  },
})
