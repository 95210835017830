
import EquipmentItem from '@/components/insurances/materialInsurance/equipmentItem.vue'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { Equipment } from '@/serializer/Equipment'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'EquipmentList',
  components: {
    'equipment-item': EquipmentItem,
  },
  props: {
    equipmentList: {
      type: Array as PropType<Array<any>>,
      required: false,
      default: () => {
        return []
      },
    },
    canBeDeleted: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emit: ['deleteEquipmentFromList'],
  setup(props, context) {
    const store = useStore()
    const equipment = ref<Equipment[]>(props.equipmentList)

    const deleteEquipment = (id: string) => {
      context.emit('deleteEquipmentFromList', id)
    }

    const editEquipment = (equipment: Equipment) => {
      context.emit('editEquipment', equipment)
    }

    const isSubmitting = computed((): boolean => {
      return store.state.insurance.isSubmittingState
    })

    watch(
      () => props.equipmentList,
      () => {
        equipment.value = props.equipmentList
      }
    )

    return {
      deleteEquipment,
      editEquipment,
      isSubmitting,
      equipment,
    }
  },
})
