import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-4"
}
const _hoisted_2 = { class: "mt-3" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "mt-4 inline-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navigation_arrow = _resolveComponent("navigation-arrow")!
  const _component_inscurance_succes = _resolveComponent("inscurance-succes")!
  const _component_custom_headline_sticker = _resolveComponent("custom-headline-sticker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass({ container: _ctx.singlePage })
    }, [
      (_ctx.isDetail)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_navigation_arrow, {
              to: "/home/verzekeringen",
              text: "Terug naar overzicht"
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", { innerHTML: _ctx.titelText }, null, 8, _hoisted_3)
      ]),
      (_ctx.holderState === _ctx.HolderStates.COMPLETED)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_inscurance_succes)
          ]))
        : _createCommentVNode("", true),
      (_ctx.detail && _ctx.details.vvksComment && _ctx.holderState !== _ctx.HolderStates.COMPLETED)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5))
        : _createCommentVNode("", true),
      (_ctx.details && _ctx.holderState !== _ctx.HolderStates.COMPLETED)
        ? (_openBlock(), _createBlock(_component_custom_headline_sticker, {
            key: 3,
            text: 'Totaalprijs: ' + '€ ' + _ctx.details.totalCost
          }, null, 8, ["text"]))
        : _createCommentVNode("", true),
      (_ctx.holderState !== _ctx.HolderStates.COMPLETED)
        ? _renderSlot(_ctx.$slots, "default", {
            key: 4,
            details: _ctx.details,
            isDetail: _ctx.isDetail
          })
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.holderState === _ctx.HolderStates.COMPLETED)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_navigation_arrow, {
              to: "/home/verzekeringen",
              text: "Terug naar overzicht"
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}