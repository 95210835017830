import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { key: 4 }
const _hoisted_6 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_one_time_activity = _resolveComponent("one-time-activity")!
  const _component_non_member = _resolveComponent("non-member")!
  const _component_travel_assistance = _resolveComponent("travel-assistance")!
  const _component_temporary_vehicle = _resolveComponent("temporary-vehicle")!
  const _component_event_insurance = _resolveComponent("event-insurance")!
  const _component_material_insurance = _resolveComponent("material-insurance")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isCurrentType(_ctx.InsuranceTypes.EENMALIGE_ACTIVITEIT, _ctx.insuranceTypeState))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_one_time_activity)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isCurrentType(_ctx.InsuranceTypes.TIJDELIJKE_VERZEKERING_NIET_LEDEN, _ctx.insuranceTypeState))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_non_member)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isCurrentType(_ctx.InsuranceTypes.REIS_BIJSTAND, _ctx.insuranceTypeState))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_travel_assistance)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isCurrentType(_ctx.InsuranceTypes.TIJDELIJKE_AUTO_VERZEKERING, _ctx.insuranceTypeState))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_temporary_vehicle)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isCurrentType(_ctx.InsuranceTypes.EVENEMENTEN_VERZEKERING, _ctx.insuranceTypeState))
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_event_insurance)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isCurrentType(_ctx.InsuranceTypes.MATERIAAL_VERZEKERING, _ctx.insuranceTypeState))
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_material_insurance)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}