import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cc9ef8b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "type"]
const _hoisted_2 = { class: "btn-simple-dark mt-0 relative" }
const _hoisted_3 = { class: "absolute flex justify-center items-center w-full left-0 top-0 h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.disabled || _ctx.loadingSubmit || _ctx.isSubmitting,
    class: _normalizeClass(["btn-simple-dark", {
      ' animate-pulse2 loading': _ctx.isSubmitting || _ctx.loadingSubmit,
    }]),
    type: _ctx.type
  }, [
    _createElementVNode("a", _hoisted_2, [
      _createElementVNode("span", {
        class: _normalizeClass({ 'opacity-0': _ctx.loadingSubmit || _ctx.isSubmitting })
      }, _toDisplayString(_ctx.text), 3),
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        _createVNode(_component_loader, {
          "is-loading": _ctx.loadingSubmit || _ctx.isSubmitting
        }, null, 8, ["is-loading"])
      ], 512), [
        [_vShow, _ctx.loadingSubmit || _ctx.isSubmitting]
      ])
    ])
  ], 10, _hoisted_1))
}