
import { defineComponent } from 'vue'
import pageTitle from '../PageTitle.vue'

export default defineComponent({
  name: 'BasePage',
  components: {
    'page-title': pageTitle,
  },
})
