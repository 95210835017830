import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "md:ml-20 xs:ml-5 sm:ml-5"
}
const _hoisted_2 = {
  key: 0,
  class: "md:w-96 xs:w-72"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "md:w-96 xs:w-72"
}
const _hoisted_5 = { class: "mt-3" }
const _hoisted_6 = { class: "flex gap-1" }
const _hoisted_7 = { class: "md:ml-20 xs:ml-5 sm:ml-5" }
const _hoisted_8 = { class: "md:ml-20 xs:ml-5 sm:ml-5" }
const _hoisted_9 = { class: "flex gap-3 mt-5 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_headline_2 = _resolveComponent("custom-headline-2")!
  const _component_custom_input = _resolveComponent("custom-input")!
  const _component_multi_select = _resolveComponent("multi-select")!
  const _component_required = _resolveComponent("required")!
  const _component_select_non_member = _resolveComponent("select-non-member")!
  const _component_back_button = _resolveComponent("back-button")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock("form", {
    id: "NonMember",
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
  }, [
    _createVNode(_component_custom_headline_2, { text: "Activiteit" }),
    (_ctx.values)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_custom_input, {
            type: _ctx.InputTypes.TEXT_AREA,
            rules: "required",
            name: "nature",
            label: "Aard van de activiteit"
          }, null, 8, ["type"]),
          (typeof _ctx.values.country === 'object' || !_ctx.values.country)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_multi_select, {
                  id: "country",
                  rules: "required",
                  "insurance-type-id": "2",
                  object: true,
                  "track-by": "name",
                  "value-prop": "name",
                  repository: _ctx.CountryRepository,
                  "resolve-on-load": true,
                  options: _ctx.values.country ? [_ctx.values.country] : [{ id: '3232', name: 'België' }],
                  "extra-option": { id: '3232', name: 'België' },
                  searchable: true,
                  label: "Land",
                  placeholder: "Zoek op naam"
                }, null, 8, ["repository", "options"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.values.country)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.values.country.name === '' || _ctx.values.country.name === 'België')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_multi_select, {
                        id: "postCodeCity",
                        object: true,
                        "track-by": "label",
                        "value-prop": "label",
                        repository: _ctx.BelgianCitySearchRepository,
                        "resolve-on-load": true,
                        options: _ctx.values.postCodeCity ? [_ctx.values.postCodeCity] : [],
                        searchable: true,
                        label: "Gemeente",
                        rules: "required",
                        placeholder: "Zoek op naam/postcode"
                      }, null, 8, ["repository", "options"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_custom_headline_2, { text: "Te verzekeren personen" }),
        _createVNode(_component_required, {
          rules: "required",
          class: "mt-3"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_select_non_member, {
        id: "nonMembers",
        "is-extra-information-comment": true,
        rules: "required"
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_custom_headline_2, { text: "Opmerkingen" }),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_custom_input, {
          type: _ctx.InputTypes.TEXT_AREA,
          name: "comment",
          label: ""
        }, null, 8, ["type"])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_back_button, {
        stateName: "setHolderState",
        backToState: _ctx.HolderStates.GENERAL
      }, null, 8, ["backToState"]),
      _createVNode(_component_custom_button, { text: "Volgende" }),
      (!_ctx.isEdit)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "link-inline cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveAsDraft()))
          }, "Opslaan als concept"))
        : _createCommentVNode("", true),
      (_ctx.isDraftEdit)
        ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            class: "link-inline cursor-pointer",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.patchDraft()))
          }, "Opslaan als concept"))
        : _createCommentVNode("", true),
      _createVNode(_component_loader, { "is-loading": _ctx.isSavingDraft }, null, 8, ["is-loading"])
    ])
  ], 32))
}