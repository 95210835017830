
import basePage from './components/semantic/BasePage.vue'
import { defineRules } from '@/veeValidate/rules'
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'App',
  components: {
    'base-page': basePage,
  },
  setup() {
    document.title = 'Verzekeringen'
    const store = useStore()
    defineRules(store)
  },
})
