import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-0"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("strong", null, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.textWidth)
    }, [
      (!_ctx.bold)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.text), 1))
        : _createCommentVNode("", true),
      (_ctx.bold)
        ? (_openBlock(), _createElementBlock("strong", _hoisted_2, _toDisplayString(_ctx.text), 1))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}