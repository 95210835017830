
import ClaimDetail from '@/components/requestClaim/detail/claimDetail.vue'
import { InputTypes } from '@/enums/inputTypes'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClaimDetailPage',
  components: {
    'claim-detail': ClaimDetail,
  },
  setup() {

    return {
      InputTypes,
    }
  }
})
