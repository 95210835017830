
import { ClaimHolderStates } from '@/enums/ClaimholderStates'
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'BreadCrumbItem',
  props: {
    index: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    visibleOnState: {
      type: String,
      required: true,
    },
    isChangeStatePossible: {
      type: Boolean,
      Required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const claimHolderState = computed((): ClaimHolderStates => {
      return store.state.claim.claimHolderState
    })

    const changeState = () => {
      if (props.isChangeStatePossible) {
        store.dispatch('setClaimHolderState', props.visibleOnState)
      }
    }
    return {
      changeState,
      claimHolderState,
    }
  },
})
