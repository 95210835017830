import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "ml-0 w-full h1" }
const _hoisted_3 = { class: "flex gap-5 xs:flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, "Welkom " + _toDisplayString(_ctx.user.firstName), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_link, { to: '/home/verzekeringen' }, {
        default: _withCtx(() => [
          _createVNode(_component_custom_button, { text: "verzekeringsaanvragen" })
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: '/home/schadeaangiftes' }, {
        default: _withCtx(() => [
          _createVNode(_component_custom_button, {
            class: "xs:w-100",
            text: "Schadeaangiftes"
          })
        ]),
        _: 1
      })
    ])
  ]))
}