import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full shadow-md border-b-2 border-black bg-white p-2 h-full inline-block text-left d-flex flex-col justify-content-between"
}
const _hoisted_2 = { class: "py-3" }
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = { class: "mt-1" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.nonMember)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "top"),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.nonMember.firstName + ' ' + _ctx.nonMember.lastName), 1)
            ]),
            _createElementVNode("div", null, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("p", null, _toDisplayString(_ctx.nonMember.street + ' ' + _ctx.nonMember.number + (_ctx.nonMember.letterBox ? ' Bus ' + _ctx.nonMember.letterBox : '') + ', ' + _ctx.nonMember.postCodeCity.postalCode + ' ' + _ctx.nonMember.postCodeCity.city), 1)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("p", null, _toDisplayString(_ctx.nonMember.birthDate), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.nonMember.comment)
              ? (_openBlock(), _createElementBlock("strong", _hoisted_5, "Opmerking"))
              : _createCommentVNode("", true),
            _createElementVNode("p", null, _toDisplayString(_ctx.nonMember.comment), 1)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}