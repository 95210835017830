
import { InsuranceRepository } from '@/repositories/insurances/insuranceRepository'
import { DraftRepository } from '@/repositories/insurances/draftRepository'
import CallToAction from '../components/customHeadlines/CallToAction.vue'
import CustomButton from '../components/CustomButton.vue'
import { ResponsibleMember } from '@/serializer/ResponsibleMember'
import RepositoryFactory from '@/repositories/repositoryFactory'
import customList from '../components/semantic/CustomList.vue'
import MultiSelect from '@/components/inputs/MultiSelect.vue'
import { ArrayResult } from '@/serializer/ArrayResult'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { Group } from '@/serializer/Group'

export default defineComponent({
  name: 'InsurancesHome',
  components: {
    'call-to-action': CallToAction,
    'custom-button': CustomButton,
    'multi-select': MultiSelect,
    'custom-list': customList,
  },
  setup: () => {
    const store = useStore()
    store.dispatch('resetStates')
    const data = ref<ArrayResult>()
    const dataDisabled = ref(false);
    const drafts = ref<ArrayResult>()
    const draftsDisabled = ref(false);
    const userData = ref<ResponsibleMember>(store.getters.user)

    const getInsurances = () => {
      if (dataDisabled.value) {
        return;
      }
      dataDisabled.value = true;
      RepositoryFactory.get(InsuranceRepository)
        .getArray('/insurances/?page=1&page_size=10')
        .then((res: ArrayResult) => {
          data.value = res
        })
    }

    const getPreviousInsurances = (previous: string) => {
      RepositoryFactory.get(InsuranceRepository)
        .getArray(previous)
        .then((res: ArrayResult) => {
          data.value = res
        })
    }

    const getNextInsurances = (next: string) => {
      RepositoryFactory.get(InsuranceRepository)
        .getArray(next)
        .then((res: ArrayResult) => {
          data.value = res
        })
    }

    const getDrafts = () => {
      if (draftsDisabled.value) {
        return;
      }
      draftsDisabled.value = true;
      RepositoryFactory.get(DraftRepository)
        .getArray('/insurance_drafts/?page=1&page_size=10')
        .then((res: ArrayResult) => {
          drafts.value = res
        })
    }

    const getPreviousDrafts = (previous: string) => {
      RepositoryFactory.get(DraftRepository)
        .getArray(previous)
        .then((res: ArrayResult) => {
          drafts.value = res
        })
    }

    const getNextDrafts = (next: string) => {
      RepositoryFactory.get(DraftRepository)
        .getArray(next)
        .then((res: ArrayResult) => {
          drafts.value = res
        })
    }

    const removeDraft = () => {
      getDrafts()
    }

    const addSelectionInsurances = (selection: Group) => {
      RepositoryFactory.get(InsuranceRepository)
        .getArray('/insurances/?search=' + selection.id + '&page=1&page_size=10')
        .then((res: ArrayResult) => {
          data.value = res
        })
    }

    return {
      addSelectionInsurances,
      getInsurances,
      getPreviousInsurances,
      getNextInsurances,
      getDrafts,
      getPreviousDrafts,
      getNextDrafts,
      removeDraft,
      userData,
      drafts,
      data,
    }
  },
})
