
import BreadCrumbItemClaim from '@/components/requestClaim/requestBreadCrumb/BreadCrumbItemClaim.vue'
import { ClaimHolderStates } from '@/enums/ClaimholderStates'
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'RequestBreadCrumb',
  components: {
    'bread-crumb-item': BreadCrumbItemClaim,
  },
  setup() {
    const store = useStore()

    const claimHolderState = computed((): ClaimHolderStates => {
      return store.state.claim.claimHolderState
    })
    return {
      ClaimHolderStates,
      claimHolderState,
    }
  },
})
