import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-462882a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-5" }
const _hoisted_2 = { class: "md:w-96 xs:w-72" }
const _hoisted_3 = { class: "md:w-96 xs:w-72" }
const _hoisted_4 = { class: "md:w-96 xs:w-72" }
const _hoisted_5 = { class: "md:w-96 xs:w-72" }
const _hoisted_6 = { class: "flex gap-3 mt-5 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_headline_2 = _resolveComponent("custom-headline-2")!
  const _component_custom_input = _resolveComponent("custom-input")!
  const _component_display_content_checkbox = _resolveComponent("display-content-checkbox")!
  const _component_back_button = _resolveComponent("back-button")!
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createElementBlock("form", {
    id: "RequestInsuranceGeneral",
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_custom_headline_2, { text: "Zijn er andere personen betrokken?" }),
      _createElementVNode("div", null, [
        _createElementVNode("div", null, [
          _createVNode(_component_display_content_checkbox, {
            onCheckChanged: _cache[0] || (_cache[0] = ($event: any) => (_ctx.involvedPartyCheck($event))),
            text: "Is het ongeval te wijten aan een fout van iemand anders?"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_custom_input, {
                  maxlength: 1024,
                  type: _ctx.InputTypes.TEXT,
                  name: "involvedPartyName",
                  label: "Naam",
                  rules: "fillInCheck:@involvedPartyDescription"
                }, null, 8, ["type"]),
                _createVNode(_component_custom_input, {
                  maxlength: 1024,
                  type: _ctx.InputTypes.TEXT,
                  name: "involvedPartyDescription",
                  label: "Adres",
                  rules: "fillInCheck:@involvedPartyBirthdate"
                }, null, 8, ["type"]),
                _createVNode(_component_custom_input, {
                  class: "mt-1",
                  type: _ctx.InputTypes.DATE,
                  name: "involvedPartyBirthdate",
                  label: "Geboortedatum"
                }, null, 8, ["type"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_display_content_checkbox, {
            onCheckChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.officialReportCheck($event))),
            text: "Werd er een vaststelling gedaan door een verbaliserende autoriteit (bv politie)?"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_custom_input, {
                  maxlength: 1024,
                  type: _ctx.InputTypes.TEXT,
                  name: "officialReportDescription",
                  label: "Welke",
                  rules: "fillInCheck:@pvNumber"
                }, null, 8, ["type"]),
                _createVNode(_component_custom_input, {
                  maxlength: 30,
                  class: "mt-1",
                  type: _ctx.InputTypes.TEXT,
                  name: "pvNumber",
                  label: "Nummer van proces-verbaal"
                }, null, 8, ["type"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_display_content_checkbox, {
            onCheckChanged: _cache[2] || (_cache[2] = ($event: any) => (_ctx.witnessCheck($event))),
            text: "Was er een getuige?"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_custom_input, {
                  maxlength: 1024,
                  type: _ctx.InputTypes.TEXT,
                  name: "witnessName",
                  label: "Naam"
                }, null, 8, ["type"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_custom_input, {
                  maxlength: 1024,
                  type: _ctx.InputTypes.TEXT,
                  name: "witnessDescription",
                  label: "Adres"
                }, null, 8, ["type"])
              ])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_back_button, {
        stateName: "setClaimHolderState",
        backToState: _ctx.ClaimHolderStates.TWO
      }, null, 8, ["backToState"]),
      _createVNode(_component_custom_button, { text: "Volgende" })
    ])
  ], 32))
}