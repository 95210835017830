
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import Loader from '@/components/semantic/Loader.vue'

export default defineComponent({
  name: 'CustomButton',
  components: {
    loader: Loader,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      default: 'submit',
      required: false,
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup() {
    const store = useStore()

    const isSubmitting = computed((): boolean => {
      return store.state.insurance.isSubmittingState
    })

    return {
      isSubmitting,
    }
  },
})
