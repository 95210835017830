
import { Member } from '@/serializer/Member'
import { defineComponent, PropType } from 'vue'
import PhoneNumber from '@/components/semantic/PhoneNumber.vue'

export default defineComponent({
  props: {
    member: {
      type: Object as PropType<Member>,
      required: true,
    },
  },
  components: {
    'phone-number': PhoneNumber,
  },
})
