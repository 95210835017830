
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CallToAction',
  props: {
    text: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: false,
      default: undefined,
    },
  },
})
