
import ResponsibleMemberDetail from '@/components/semantic/detail/ResponsibleMemberDetail.vue'
import CustomHeadline2 from '@/components/customHeadlines/CustomHeadline2.vue'
import ActivityDetail from '@/components/semantic/detail/ActivityDetail.vue'
import { EventRepository } from '@/repositories/insurances/eventRepository'
import BaseDetail from '@/components/semantic/detail/BaseDetail.vue'
import ParticipantsFileSection from '@/components/semantic/ParticipantsFileSection.vue'
import LabelOutput from '@/components/semantic/LabelOutput.vue'
import FileUpload from '@/components/semantic/FileUpload.vue'
import { formatEventDate } from '@/helpers/formatHelper'
import { computed, defineComponent } from 'vue'
import { HolderStates } from '@/enums/holderStates'
import { InputTypes } from '@/enums/inputTypes'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'EventInsuranceDetail',
  components: {
    'responsible-member-detail': ResponsibleMemberDetail,
    'activity-detail': ActivityDetail,
    'label-output': LabelOutput,
    'base-detail': BaseDetail,
    ParticipantsFileSection
  },
  props: {
    singlePage: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup() {
    const store = useStore()

    const eventInsuranceState = computed(() => {
      return store.state.insurance.eventState
    })

    const holderState = computed((): HolderStates => {
      return store.state.insurance.holderState
    })

    return {
      eventInsuranceState,
      EventRepository,
      formatEventDate,
      CustomHeadline2,
      HolderStates,
      holderState,
      InputTypes,
      FileUpload,
    }
  },
})
