import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_member_list = _resolveComponent("member-list")!
  const _component_members_side_bar = _resolveComponent("members-side-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", { name: _ctx.id }, [
      _createVNode(_component_ErrorMessage, {
        name: _ctx.id,
        class: "text-red text-sm block mt-1 w-80"
      }, null, 8, ["name"])
    ], 8, _hoisted_1),
    _createElementVNode("a", {
      class: "cursor-pointer btn-simple-green mb-4",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openSideBar()))
    }, " + Voeg lid toe "),
    _createVNode(_component_member_list, {
      "can-be-deleted": true,
      "members-list": _ctx.members,
      onDeleteMemberFromList: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteMemberFromList($event)))
    }, null, 8, ["members-list"]),
    (_ctx.generalInsuranceState.group)
      ? (_openBlock(), _createBlock(_component_members_side_bar, {
          key: 0,
          start: _ctx.generalInsuranceState.startDate,
          end: _ctx.generalInsuranceState.endDate,
          isDisplay: _ctx.isDisplay,
          "onUpdate:isDisplay": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isDisplay) = $event)),
          group: _ctx.generalInsuranceState.group.id,
          "existing-list": _ctx.members,
          title: "Lid",
          onAddMemberToList: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addMemberToList($event)))
        }, null, 8, ["start", "end", "isDisplay", "group", "existing-list"]))
      : _createCommentVNode("", true)
  ], 64))
}