import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "md:ml-20 xs:ml-5 sm:ml-5" }
const _hoisted_2 = { action: "" }
const _hoisted_3 = ["disabled", "id", "name", "value", "onChange"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.insuranceTypes, (insuranceType, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: insuranceType.id,
          class: "py-1"
        }, [
          _withDirectives(_createElementVNode("input", {
            disabled: _ctx.disabled,
            id: index,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selection) = $event)),
            class: "cursor-pointer",
            type: "radio",
            name: index,
            value: insuranceType.value,
            onChange: ($event: any) => (_ctx.setInsuranceTypeState(insuranceType.value))
          }, null, 40, _hoisted_3), [
            [_vModelRadio, _ctx.selection]
          ]),
          _createElementVNode("label", {
            for: index,
            class: "ml-2 cursor-pointer"
          }, _toDisplayString(insuranceType.name), 9, _hoisted_4)
        ]))
      }), 128))
    ])
  ]))
}