
import NonMemberItem from '@/components/insurances/nonMembersInsurance/nonMemberItem.vue'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { NonMember } from '@/serializer/NonMember'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'NonMembersList',
  components: {
    'non-member-item': NonMemberItem,
  },
  props: {
    nonMembersList: {
      type: Array as PropType<Array<any>>,
      required: false,
      default: () => {
        return []
      },
    },
    canBeDeleted: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emit: ['deleteNonMemberFromList', 'selectedIndex'],
  setup(props, context) {
    const nonMembers = ref<NonMember[]>(props.nonMembersList)

    watch(
      () => props.nonMembersList,
      () => {
        nonMembers.value = props.nonMembersList
      }
    )

    const deleteNonMember = (id: string) => {
      context.emit('deleteNonMemberFromList', id)
    }

    const editNonMember = (nonMember: NonMember, index: number) => {
      context.emit('editNonMember', nonMember)
      context.emit('selectedIndex', index)
    }

    const store = useStore()

    const isSubmitting = computed((): boolean => {
      return store.state.insurance.isSubmittingState
    })

    return {
      deleteNonMember,
      editNonMember,
      isSubmitting,
      nonMembers,
    }
  },
})
