import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_non_member_list = _resolveComponent("non-member-list")!
  const _component_non_member_side_bar = _resolveComponent("non-member-side-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", { name: _ctx.id }, [
      _createVNode(_component_ErrorMessage, {
        name: _ctx.id,
        class: "text-red text-sm block mt-1 w-80"
      }, null, 8, ["name"])
    ], 8, _hoisted_1),
    _createElementVNode("a", {
      class: "cursor-pointer btn-simple-green mb-4",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openSideBar()))
    }, " + Voeg persoon toe "),
    _createVNode(_component_non_member_list, {
      "can-be-deleted": true,
      "non-members-list": _ctx.nonMembers,
      onDeleteNonMemberFromList: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteNonMemberFromList($event))),
      onEditNonMember: _cache[2] || (_cache[2] = ($event: any) => (_ctx.editNonMember($event))),
      onSelectedIndex: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedElement($event)))
    }, null, 8, ["non-members-list"]),
    _createVNode(_component_non_member_side_bar, {
      "side-bar-state": _ctx.sideBarState,
      "onUpdate:side-bar-state": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sideBarState) = $event)),
      "is-extra-information-comment": _ctx.isExtraInformationComment,
      "existing-list": _ctx.nonMembers,
      title: _ctx.sideBarState.state === 'edit' ? 'Bewerk persoonsgegevens' : 'Persoonsgegevens',
      onAddCreatedNonMemberToList: _cache[5] || (_cache[5] = ($event: any) => (_ctx.addCreatedNonMemberToList($event))),
      onUpdateMemberInList: _cache[6] || (_cache[6] = ($event: any) => (_ctx.updateMemberInList($event)))
    }, null, 8, ["side-bar-state", "is-extra-information-comment", "existing-list", "title"])
  ], 64))
}