import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36a7eccb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-col h-full px-4 pt-4" }
const _hoisted_2 = { class: "h-full overflow-y-scroll mt-4 pb-24" }
const _hoisted_3 = {
  key: 0,
  class: "mt-4 border-t-2 w-100 border-black"
}
const _hoisted_4 = { class: "pt-3 pb-4 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_input = _resolveComponent("search-input")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_member_item = _resolveComponent("member-item")!
  const _component_base_side_bar = _resolveComponent("base-side-bar")!

  return (_openBlock(), _createBlock(_component_base_side_bar, {
    isOverflowHidden: _ctx.isOverflowHidden,
    isDisplay: _ctx.display,
    "onUpdate:isDisplay": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.display) = $event)),
    name: "Member",
    title: _ctx.title
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          (_ctx.generalInsuranceState.group)
            ? (_openBlock(), _createBlock(_component_search_input, {
                key: 0,
                group: _ctx.generalInsuranceState.group.id,
                loading: _ctx.loading,
                "onUpdate:loading": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loading) = $event)),
                name: "member",
                placeholder: "Zoek op naam",
                repository: _ctx.MemberRepository,
                onFetchedOptions: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchedOptions($event)))
              }, null, 8, ["group", "loading", "repository"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.selectedMembers.length > 0)
            ? (_openBlock(), _createElementBlock("hr", _hoisted_3))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedMembers, (member) => {
            return (_openBlock(), _createElementBlock("div", {
              key: member.id,
              class: "w-100"
            }, [
              _createVNode(_component_member_item, { member: member }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_custom_button, {
                        type: "button",
                        text: 
                    _ctx.existingList.some((m) => m.firstName === member.firstName && m.lastName === member.lastName && m.street === member.street && m.number === member.number) ? 'Toegevoegd' : 'Voeg toe'
                  ,
                        disabled: 
                    _ctx.existingList && _ctx.existingList.some((m) => m.firstName === member.firstName && m.lastName === member.lastName && m.street === member.street && m.number === member.number)
                      ? true
                      : false
                  ,
                        onClick: ($event: any) => (_ctx.addMember(member))
                      }, null, 8, ["text", "disabled", "onClick"])
                    ])
                  ])
                ]),
                _: 2
              }, 1032, ["member"])
            ]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["isOverflowHidden", "isDisplay", "title"]))
}