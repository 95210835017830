
import InsuranceApplicant from '@/components/requestInsurance/insuranceApplicant/insuranceApplicant.vue'
import NonMemberSideBar from '@/components/insurances/nonMembersInsurance/nonMemberSideBar.vue'
import { BelgianCitySearchRepository } from '@/repositories/belgianCitySearchRepository'
import MemberSideBar from '@/components/insurances/travelAssistance/membersSideBar.vue'
import QuestionDisclaimer from '@/components/disclaimers/questionDisclaimer.vue'
import CustomHeadline2 from '@/components/customHeadlines/CustomHeadline2.vue'
import { scrollToFirstError, useScrollToTop } from '@/veeValidate/helpers'
import { CountryRepository } from '@/repositories/countriesRepository'
import InfoAlert from '@/components/requestInsurance/InfoAlert.vue'
import { Country, CountryDeserializer } from '@/serializer/Country'
import { ResponsibleMember } from '@/serializer/ResponsibleMember'
import RepositoryFactory from '@/repositories/repositoryFactory'
import LabelOutput from '@/components/semantic/LabelOutput.vue'
import { ClaimHolderStates } from '@/enums/ClaimholderStates'
import MultiSelect from '@/components/inputs/MultiSelect.vue'
import CustomInput from '@/components/inputs/CustomInput.vue'
import { defineComponent, computed, ref, watch } from 'vue'
import AuthRepository from '@/repositories/authRepository'
import Required from '@/components/semantic/Required.vue'
import CustomButton from '@/components/CustomButton.vue'
import { sideBarState } from 'vue-3-component-library'
import { useForm, ErrorMessage } from 'vee-validate'
import { NonMember } from '@/serializer/NonMember'
import { Claim } from '@/serializer/claims/claim'
import { InputTypes } from '@/enums/inputTypes'
import { Member } from '@/serializer/Member'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { ClaimRepository } from '@/repositories/claims/claimRepository'

export default defineComponent({
  name: 'RequestClaimIdentities',
  components: {
    'question-disclaimer': QuestionDisclaimer,
    'insurance-applicant': InsuranceApplicant,
    'non-member-side-bar': NonMemberSideBar,
    'custom-headline-2': CustomHeadline2,
    'members-side-bar': MemberSideBar,
    'custom-button': CustomButton,
    'multi-select': MultiSelect,
    'custom-input': CustomInput,
    'info-alert': InfoAlert,
    'label-output': LabelOutput,
    ErrorMessage,
    Required,
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const initialCountry = ref<Country>(CountryDeserializer({ id: '3232', name: 'België' }))
    const nonMemberSideBarState = ref<sideBarState<NonMember>>({ state: 'hide' })
    const userData = ref<ResponsibleMember>(store.getters.user)
    const isMemberSideBarDisplay = ref<boolean>(false)
    const { scrollToTopOfPage } = useScrollToTop()
    const selectedGender = ref<string>()
    const isEdit = !!route.params.id
    const isFieldsVisible = ref<boolean>(false)
    const isSelectedVictim = ref<boolean>(false)

    const { handleSubmit, values, validate, isSubmitting } = useForm<Claim>({
      initialValues: {
        victim: {
          country: initialCountry.value,
        },
      },
    })

    const claimGroups = ref<Array<any>>([])

    const getClaimGroups = () => {
      RepositoryFactory.get(ClaimRepository)
        .getClaimGroupsByPermissions()
        .then((res) => {
          claimGroups.value = res
          values.group = res[0]
        })
    }

    getClaimGroups()

    const claimState = computed((): Claim => {
      return store.state.claim.claimState
    })

    const onSubmit = async () => {
      await validate().then((validation: any) => scrollToFirstError(validation, 'RequestClaimIdentities'))
      handleSubmit(async (values: any) => {
        const newClaimState = ref<Claim>({
          id: values.id,
          group: values.group,
          groupLeader: userData.value,
          victim: values.victim,
          victimMember: values.victim.isMember ? values.victimMember : undefined,
          victimNonMember: !values.victim.isMember ? values.victimMember : undefined,
          file: values.file,
          gender: values.victim.gender,
          victimBirthDate: values.victim.birthDate,
          victimEmail: values.victim.email,
        })

        store.dispatch('setClaimState', newClaimState)
        store.dispatch('setClaimHolderState', ClaimHolderStates.TWO)
      })()
    }

    const isRefreshing = ref<boolean>(false)
    const refreshGroups = () => {
      isRefreshing.value = true
      RepositoryFactory.get(AuthRepository)
        .me()
        .then((user: any) => {
          store.dispatch('setUser', user).then(() => {
            userData.value = store.getters.user
            isRefreshing.value = false
          })
        })
    }

    const openMemberSideBar = () => {
      isMemberSideBarDisplay.value = true
    }

    const openNonMemberSideBar = () => {
      nonMemberSideBarState.value = { state: 'new' }
    }

    const addMember = (member: Member) => {
      if (values.victim) {
        displayFields()
        values.victimMember = member.id
        values.victim.id = member.id
        values.victim.firstName = member.firstName
        values.victim.lastName = member.lastName
        values.victim.street = member.street
        values.victim.number = member.number
        values.victim.letterBox = member.letterBox
        values.victim.postCodeCity = member.postCodeCity
        values.victim.birthDate = member.birthDate
        values.victim.gender = member.gender
        values.victim.email = member.email
        values.victim.membershipNumber = member.membershipNumber
        values.victim.isMember = member.isMember
        values.victim.groupAdminId = member.isMember ? member.groupAdminId : undefined
        values.victim.nonMember = !member.isMember ? member.id : undefined
        selectedGender.value = member.gender
      }
      isSelectedVictim.value = true
    }

    const displayFields = () => {
      if (isSelectedVictim.value && values.victim) {
        values.victimMember = ''
        values.victim.id = ''
        values.victim.firstName = ''
        values.victim.lastName = ''
        values.victim.street = ''
        values.victim.number = ''
        values.victim.letterBox = ''
        values.victim.postCodeCity = undefined
        values.victim.birthDate = ''
        values.victim.email = ''
        values.victim.membershipNumber = ''
        values.victim.isMember = false
        values.victim.gender = ''
        values.victim.bankAccount = ''
      }

      isSelectedVictim.value = false
      isFieldsVisible.value = true
    }

    scrollToTopOfPage()

    watch(
      () => selectedGender.value,
      () => {
        if (values.victim) {
          values.victim.gender = selectedGender.value
        }
      }
    )

    return {
      BelgianCitySearchRepository,
      isMemberSideBarDisplay,
      nonMemberSideBarState,
      openNonMemberSideBar,
      openMemberSideBar,
      CountryRepository,
      isFieldsVisible,
      displayFields,
      refreshGroups,
      isRefreshing,
      isSubmitting,
      InputTypes,
      claimState,
      addMember,
      userData,
      onSubmit,
      selectedGender,
      isEdit,
      values,
      isSelectedVictim,
      claimGroups,
    }
  },
})
