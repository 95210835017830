
import { BelgianCitySearchRepository } from '@/repositories/belgianCitySearchRepository'
import MemberSiderbar from '@/components/insurances/travelAssistance/membersSideBar.vue'
import DriverList from '@/components/insurances/tempCarInsurance/driverList.vue'
import { IS_NO_DRIVER, SelectDriver } from '@/serializer/selectDriver'
import TipTempVehicle from '@/components/tips/tipTempVehicle.vue'
import CustomInput from '@/components/inputs/CustomInput.vue'
import MultiSelect from '@/components/inputs/MultiSelect.vue'
import { computed, defineComponent, ref, watch } from 'vue'
import { ErrorMessage, useField } from 'vee-validate'
import { InputTypes } from '@/enums/inputTypes'
import { Driver } from '@/serializer/Driver'
import { Owner } from '@/serializer/Owner'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'SelectParticipant',
  components: {
    'members-side-bar': MemberSiderbar,
    'driver-list': DriverList,
    'custom-input': CustomInput,
    'multi-select': MultiSelect,
    'tip-temp-vehicle': TipTempVehicle,
    ErrorMessage,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    rules: {
      type: [Object, String, Function],
      default: '',
      required: false,
    },
  },
  setup(props) {
    const store = useStore()

    const { value: selectDriverField } = useField<any>(props.id, props.rules, {})

    const { value: input } = useField<Owner>('input', 'required', {})

    watch(
      () => selectDriverField.value.isDriverOwner,
      () => {
        store.dispatch('setIsDriverOwnerState', selectDriverField.value.isDriverOwner)
      }
    )

    const isDisplay = ref<boolean>(false)

    const openSideBar = () => {
      isDisplay.value = true
    }
    const addMemberToDriverList = (driver: Driver) => {
      selectDriverField.value.drivers.push(driver)
    }

    const deleteMemberFromList = (id: string) => {
      selectDriverField.value.drivers.splice(Number(id), 1)
    }

    const isSubmitting = computed((): boolean => {
      return store.state.insurance.isSubmittingState
    })

    const generalInsuranceState = computed(() => {
      return store.state.insurance.generalInsuranceState
    })

    return {
      BelgianCitySearchRepository,
      addMemberToDriverList,
      generalInsuranceState,
      deleteMemberFromList,
      selectDriverField,
      IS_NO_DRIVER,
      isSubmitting,
      openSideBar,
      InputTypes,
      isDisplay,
      input,
    }
  },
})
