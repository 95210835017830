
import { scrollToFirstError, useScrollToTop, useFormSendWithSuccess } from '@/veeValidate/helpers'
import NonMemberItem from '@/components/insurances/nonMembersInsurance/nonMemberItem.vue'
import { BelgianCitySearchRepository } from '@/repositories/belgianCitySearchRepository'
import { computed, defineComponent, PropType, ref, toRefs, watch } from 'vue'
import { NonMemberRepository } from '@/repositories/nonMemberRepository'
import { ResponsibleMember } from '@/serializer/ResponsibleMember'
import SuccessToast from '@/components/semantic/successToast.vue'
import RepositoryFactory from '@/repositories/repositoryFactory'
import CustomInput from '@/components/inputs/CustomInput.vue'
import MultiSelect from '@/components/inputs/MultiSelect.vue'
import SearchInput from '@/components/inputs/SearchInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import { NonMember } from '@/serializer/NonMember'
import { InputTypes } from '@/enums/inputTypes'
import { useForm } from 'vee-validate'
import { useStore } from 'vuex'
import { BaseSideBar, sideBarState, option } from 'vue-3-component-library'

export default defineComponent({
  name: 'NonMemberSideBar',
  components: {
    'custom-button': CustomButton,
    'base-side-bar': BaseSideBar,
    'custom-input': CustomInput,
    'multi-select': MultiSelect,
    'non-member-item': NonMemberItem,
    'search-input': SearchInput,
    'success-toast': SuccessToast,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    existingList: {
      type: Array,
      default: () => {
        return []
      },
    },
    closeOnAdd: {
      type: Boolean,
      default: false,
      required: false,
    },
    sideBarState: {
      type: Object as PropType<sideBarState<NonMember>>,
      required: true,
      default: () => {
        'hide'
      },
    },
    isExtraInformationComment: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOverflowHidden: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['update:sideBarState', 'addCreatedNonMemberToList', 'updateMemberInList'],
  setup(props, context) {
    const store = useStore()
    const generalInsuranceState = computed(() => {
      return store.state.insurance.generalInsuranceState
    })
    const user = ref<ResponsibleMember>(store.getters.user)
    const { resetForm, errors, handleSubmit, validate, meta, values, isSubmitting } = useForm<NonMember>()
    // @ts-ignore
    const { formSendWithSuccess } = useFormSendWithSuccess<NonMember>(meta)
    const selected = computed(() => (props.sideBarState.state === 'list' ? 'BestaandNonMember' : 'NieuwNonMember'))
    const selectedNonMembers = ref<NonMember[]>([])
    const loading = ref<boolean>(false)
    const { formDiv, scrollToTop } = useScrollToTop()
    const { sideBarState } = toRefs(props)

    const options = ref<option[]>([
      { text: 'Nieuw', value: 'Nieuw' },
      { text: 'Uit eerdere aanvragen', value: 'Bestaand' },
    ])

    const onSubmit = async () => {
      await validate().then((validation: any) => scrollToFirstError(validation, 'addNewNonMember'))
      handleSubmit(async (values: NonMember) => {
        if (props.sideBarState.state === 'new' || props.sideBarState.state === 'edit') {
          console.log('VALUES:', values)
          const nonMember = ref<NonMember>({
            id: values.id,
            inuitsId: values.inuitsId,
            lastName: values.lastName,
            firstName: values.firstName,
            phoneNumber: values.phoneNumber ? values.phoneNumber : '/',
            birthDate: values.birthDate ? values.birthDate : undefined,
            street: values.street,
            number: values.number,
            letterBox: values.letterBox,
            comment: values.comment,
            postCodeCity: values.postCodeCity,
            group: generalInsuranceState.value.group.id,
          })
          if (props.sideBarState.state === 'edit') {
            await editNonMember(nonMember.value)
          } else {
            await postNonMember(nonMember.value)
          }
        }

        // selectedNonMembers.value = []
      })()
    }

    const addNonMember = (nonMember: NonMember) => {
      if (!props.existingList.includes(nonMember)) {
        context.emit('addCreatedNonMemberToList', nonMember)
      }
      if (props.closeOnAdd) {
        closeSideBar()
      }
    }

    const editNonMember = async (data: NonMember) => {
      formSendWithSuccess.value = false
      console.log('data to edit: ', data)
      if (data.inuitsId) {
        await RepositoryFactory.get(NonMemberRepository)
          .update(data.inuitsId, data)
          .then((completed: NonMember) => {
            completed.inuitsId = data.inuitsId
            context.emit('updateMemberInList', completed)
            closeSideBar()
          })
      }
    }

    const postNonMember = async (data: NonMember) => {
      formSendWithSuccess.value = false
      await RepositoryFactory.get(NonMemberRepository)
        .create(data)
        .then((completed: NonMember) => {
          completed.inuitsId = completed.id
          context.emit('addCreatedNonMemberToList', completed)
          formSendWithSuccess.value = true
          scrollToTop()
          resetForm()
          if (props.closeOnAdd) {
            closeSideBar()
          }
        })
    }

    const fetchedOptions = (options: any) => {
      selectedNonMembers.value = []
      options.forEach((nonMember: any) => {
        selectedNonMembers.value.push(nonMember.value)
      })
      loading.value = false
    }

    const closeSideBar = () => {
      context.emit('update:sideBarState', { state: 'hide' })
    }

    const changeSideBar = (options: 'NieuwNonMember' | 'BestaandNonMember') => {
      if (options === 'NieuwNonMember') {
        context.emit('update:sideBarState', { state: 'new' })
      }

      if (options === 'BestaandNonMember') {
        context.emit('update:sideBarState', { state: 'list' })
      }
    }

    watch(sideBarState, (value: sideBarState<NonMember>) => {
      if (value.state === 'edit') {
        formSendWithSuccess.value = false
        resetForm({
          values: {
            id: value.entity.id,
            inuitsId: value.entity.inuitsId,
            lastName: value.entity.lastName,
            firstName: value.entity.firstName,
            phoneNumber: value.entity.phoneNumber,
            birthDate: value.entity.birthDate,
            street: value.entity.street,
            number: value.entity.number,
            letterBox: value.entity.letterBox,
            comment: value.entity.comment,
            postCodeCity: value.entity.postCodeCity,
          },
        })
      }

      if (value.state === 'new') {
        formSendWithSuccess.value = false
        resetForm({
          values: {
            id: '',
            inuitsId: '',
            lastName: '',
            firstName: '',
            phoneNumber: '',
            birthDate: '',
            street: '',
            number: '',
            letterBox: '',
            comment: '',
            postCodeCity: {},
          },
          errors: {},
        })
      }
    })

    return {
      BelgianCitySearchRepository,
      generalInsuranceState,
      NonMemberRepository,
      formSendWithSuccess,
      selectedNonMembers,
      fetchedOptions,
      editNonMember,
      changeSideBar,
      addNonMember,
      closeSideBar,
      isSubmitting,
      InputTypes,
      selected,
      onSubmit,
      loading,
      formDiv,
      options,
      errors,
      values,
      user,
    }
  },
})
