
import MemberItem from '@/components/insurances/travelAssistance/memberItem.vue'
import { computed, defineComponent, PropType } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'MembersList',
  components: {
    'member-item': MemberItem,
  },
  props: {
    membersList: {
      type: Array as PropType<Array<any>>,
      required: false,
      default: () => {
        return []
      },
    },
    canBeDeleted: {
      type: Boolean,
      default: false,
      required: false,
    },
    owner: {
      type: String,
    },
  },
  emit: ['update:owner', 'deleteMemberFromList'],
  setup(props, context) {
    const ownerComputed = computed<any>({
      get: () => props.owner,
      set: (value: any) => context.emit('update:owner', value),
    })

    const deleteDriverFromList = (id: string) => {
      context.emit('deleteMemberFromList', id)
    }

    const store = useStore()

    const isSubmitting = computed((): boolean => {
      return store.state.insurance.isSubmittingState
    })
    return {
      deleteDriverFromList,
      isSubmitting,
      ownerComputed,
    }
  },
})
