
import CustomHeadline2 from '@/components/customHeadlines/CustomHeadline2.vue'
import ClaimListItem from './ClaimListItem.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClaimList',
  components: {
    'custom-headline-2': CustomHeadline2,
    'claim-list-item': ClaimListItem,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {}
  },
})
