
import { Equipment } from '@/serializer/Equipment'
import { Member } from '@/serializer/Member'
import { NonMember } from '@/serializer/NonMember'
import { defineComponent, PropType, ref, watch } from 'vue'
import PhoneNumber from '@/components/semantic/PhoneNumber.vue'

export default defineComponent({
  props: {
    equipment: {
      type: Object as PropType<Equipment>,
      required: true,
    },
  },
  components: {
    'phone-number': PhoneNumber,
  },
  setup(props) {
    const person = ref<Member | NonMember | undefined>(props.equipment.ownerMember ? props.equipment.ownerMember : props.equipment.ownerNonMember ? props.equipment.ownerNonMember : undefined)

    watch(
      () => props.equipment,
      () => {
        person.value = props.equipment.ownerMember ? props.equipment.ownerMember : props.equipment.ownerNonMember ? props.equipment.ownerNonMember : undefined
      }
    )
    return {
      person,
    }
  },
})
