
import { defineComponent, PropType } from 'vue'
import { Claim } from '@/serializer/claims/claim'
import { formatSingleDate } from '@/helpers/formatHelper'

export default defineComponent({
  name: 'CustomListItem',
  components: {},
  props: {
    item: {
      type: Object as PropType<Claim>,
      required: true,
    },
  },
  setup() {
    const fetchClaimById = (id: string) => {
      //DO LOGIC
      console.log('claim id:', id)
    }
    return {
      formatSingleDate,
      fetchClaimById,
    }
  },
})
