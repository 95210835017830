
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'DisplayContentCheckBox',
  props: {
    text: {
      type: String,
      required: true,
    },
    initialState: String
  },
  setup(props, { emit}) {
    const isChecked = ref<string>(props.initialState ? props.initialState : '')

    watch(
      () => isChecked.value,
      () => {
        if (isChecked.value === 'yes') {
          emit('checkChanged', "True")

        } else if (isChecked.value === 'no') {
          emit('checkChanged', "False")

        } else {
          emit('checkChanged', null)
        }
      }
    )
    // TRIGGER EVENT TO NOT SET VALUES IF CHOICE IS NO
    return {
      isChecked
    }
  },
})
