
import { InsuranceTypes, InsuranceTypeId } from '@/enums/insuranceTypes'
import { BaseRepository } from '@/repositories/baseRepository'
import RepositoryFactory from '@/repositories/repositoryFactory'
import { defineComponent, PropType, ref, watch, computed } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'SearchInput',
  props: {
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Zoek...',
    },
    repository: {
      type: Function as unknown as PropType<new () => BaseRepository>,
      required: false,
      default: Function as unknown as PropType<new () => BaseRepository>,
    },
    loading: Boolean,
    loadingSubmit: {
      type: Boolean,
      default: false,
      required: false,
    },
    group: String,
    start: String,
    end: String
  },
  setup(props, context) {
    let debounce: any
    const query = ref<string>('')
    const options = ref<any>([])
    const store = useStore()
    const insuranceTypeState = computed((): InsuranceTypes => {
      return store.state.insurance.insuranceTypeState
    })
    const search = () => {
      context.emit('update:loading', true)
      clearTimeout(debounce)
      debounce = setTimeout(() => {
        if (query.value) {
          const type = InsuranceTypeId[insuranceTypeState.value];
          RepositoryFactory.get(props.repository)
            .search(query.value + `&type=${type}`, props.group ,props.start, props.end,)
            .then((results: any) => {
              options.value = results
              context.emit('fetchedOptions', options.value)
            })
        }
      }, 1000)
    }

    return {
      options,
      search,
      query,
    }
  },
})
