
import FileItemComponent from '@/components/semantic/FileItemComponent.vue'
import RepositoryFactory from '@/repositories/repositoryFactory'
import FileRepository from '@/repositories/fileRepository'
import { defineComponent, PropType, watch } from 'vue'
import { ErrorMessage, useField } from 'vee-validate'
import { FileItem } from '@/serializer/FileItem'
import { saveAs } from 'file-saver'

export default defineComponent({
  name: 'file-upload',
  components: {
    'file-item-component': FileItemComponent,
    ErrorMessage,
  },
  props: {
    message: {
      type: String,
      default: 'Voeg hier optioneel het ingevulde geneeskundig getuigschrift, een rekening of factuur,... toe',
      required: false,
    },
    isDisplay: {
      type: Boolean,
      default: false,
      required: false,
    },
    file: {
      type: Object as PropType<FileItem>,
      required: false,
    },
    inscuranceType: {
      type: String,
      default: ''
    },
    allowedFiles: {
      type: String,
      default: "*",
      required: false
    }
  },
  setup(props, { emit}) {

    const { value: selectedFile } = useField<any>('file', 'fileSize', {})

    if (props.file) {
      watch(() => props.file, () => {
        if (props.file && props.file.name) {
          download()
        }
      })
    }

    const download = () => {
      if (props.file && props.file.id) {
        RepositoryFactory.get(FileRepository)
        .downloadParticipantsFile(props.file.id, props.inscuranceType)
        .then((res) => {
          selectedFile.value = res
          if (props.file && props.file.name) {
            selectedFile.value.name = props.file.name
            selectedFile.value.id = props.file.id
          }
        })
      }
    }

    download()

    const downloadFile = () => {
      if (props.file && props.file.id) {
        const savedAsFile = saveAs(selectedFile.value, selectedFile.value.name)
      }
    }

    const deleteFile = () => {
      selectedFile.value = undefined
      //@ts-ignore
      document.getElementById('file').value = ''
      if (props.file && props.file.id) {
        RepositoryFactory.get(FileRepository)
        .deleteParticipantsFile(props.file.id, props.inscuranceType)
      }
      emit('removeUploadedFile')
    }

    const selectFile = (data: any) => {
      selectedFile.value = data.target.files[0]
      emit('removeUploadedFile')
    }

    return {
      selectedFile,
      downloadFile,
      deleteFile,
      selectFile,
    }
  },
})
