
import CustomHeadline2 from '@/components/customHeadlines/CustomHeadline2.vue'
import CustomListItem from '../semantic/CustomListItem.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomList',
  components: {
    'custom-headline-2': CustomHeadline2,
    'custom-list-item': CustomListItem,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    isDraft: Boolean,
  },
  setup(props, context) {
    const removeDraft = (id: string) => {
      context.emit('removeDraft', id)
    }
    return {
      removeDraft,
    }
  },
})
