
import InsuranceTypeMenu from '@/components/requestInsurance/insuranceTypeMenu/InsuranceTypeMenu.vue'
import TipGeneralMaterialInsurance from '@/components/tips/tipGeneralMaterialInsurance.vue'
import TipGeneralEventInsurance from '@/components/tips/tipGeneralEventInsurance.vue'
import TipGeneralVehicleInsurance from '@/components/tips/tipGeneralVehicleInsurance.vue'
import TipGeneralOneTimeInsurance from '@/components/tips/tipGeneralOneTimeInsurance.vue'
import tipGeneralTravelAssistance from '@/components/tips/tipGeneralTravelAssistance.vue'
import CustomHeadline2 from '@/components/customHeadlines/CustomHeadline2.vue'
import InsuranceApplicant from './insuranceApplicant/insuranceApplicant.vue'
import { MaxCoverageRepository } from '@/repositories/maxCoverageRepository'
import TipGeneralNonMember from '@/components/tips/tipGeneralNonMember.vue'
import { scrollToFirstError, useScrollToTop } from '@/veeValidate/helpers'
import dateDisclaimer from '@/components/disclaimers/dateDisclaimer.vue'
import { BaseInsurance } from '@/serializer/insurances/BaseInsurance'
import InfoAlert from '@/components/requestInsurance/InfoAlert.vue'
import { ResponsibleMember } from '@/serializer/ResponsibleMember'
import RepositoryFactory from '@/repositories/repositoryFactory'
import TipChoiceHelp from '@/components/tips/tipChoiceHelp.vue'
import CustomInput from '@/components/inputs/CustomInput.vue'
import MultiSelect from '@/components/inputs/MultiSelect.vue'
import { defineComponent, computed, ref, watch } from 'vue'
import { InsuranceTypeRepos } from '@/enums/insuranceTypes'
import AuthRepository from '@/repositories/authRepository'
import CustomButton from '@/components/CustomButton.vue'
import { InsuranceTypes } from '@/enums/insuranceTypes'
import Loader from '@/components/semantic/Loader.vue'
import { HolderStates } from '@/enums/holderStates'
import { Coverage } from '@/serializer/Coverage'
import { InputTypes } from '@/enums/inputTypes'
import { useForm } from 'vee-validate'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import router from '@/router'
import moment from 'moment'

export default defineComponent({
  name: 'RequestInsuranceGeneral',
  components: {
    'tip-general-material-insurance': TipGeneralMaterialInsurance,
    'tip-general-travel-assistance': tipGeneralTravelAssistance,
    'tip-general-event-insurance': TipGeneralEventInsurance,
    'tip-general-vehicle-insurance': TipGeneralVehicleInsurance,
    'tip-general-one-time-insurance': TipGeneralOneTimeInsurance,
    'tip-general-non-member': TipGeneralNonMember,
    'insurance-applicant': InsuranceApplicant,
    'insurance-type-menu': InsuranceTypeMenu,
    'custom-headline-2': CustomHeadline2,
    'date-disclaimer': dateDisclaimer,
    'tip-choice-help': TipChoiceHelp,
    'custom-button': CustomButton,
    'custom-input': CustomInput,
    'multi-select': MultiSelect,
    'info-alert': InfoAlert,
    Loader,
  },
  setup() {
    const { scrollToTopOfPage } = useScrollToTop()
    const route = useRoute()
    const store = useStore()
    const isEdit = !!route.params.id
    const isDraftEdit = ref<boolean>(route.path.includes('draft-bewerken'))
    const userData = ref<ResponsibleMember>(store.getters.user)
    let data: any = store.getters.getCurrentInsuranceState
    const maxCoverageOptions = ref<Array<Coverage>>()
    console.log('INITIAL DRAFT DATA: ', data)
    const { handleSubmit, values, validate, isSubmitting } = useForm<BaseInsurance>({
      initialValues: {
        startDate: data.startDate ? data.startDate : '',
        startTime: data.startTime ? data.startTime : '',
        endDate: data.endDate ? data.endDate : '',
        endTime: data.endTime ? data.endTime : '',
        group: isEdit ? data.group : userData.value.scoutsGroups ? userData.value.scoutsGroups[0] : undefined,
        responsibleMember: data.responsibleMember ? data.responsibleMember : userData.value,
        insuranceOptions: data.insuranceOptions ? data.insuranceOptions : [],
        maxCoverage: data.maxCoverage ? data.maxCoverage : [],
      },
    })

    const insuranceTypeState = computed((): InsuranceTypes => {
      return store.state.insurance.insuranceTypeState
    })
    const minDate = moment().add(1, 'days').format('YYYY-MM-DD')

    const onSubmit = async () => {
      await validate().then((validation: any) => scrollToFirstError(validation, 'RequestInsuranceGeneral'))
      handleSubmit(async (values: any) => {
        const generalInsuranceState = ref<BaseInsurance>({
          startDate: values.startDate,
          startTime: values.startTime ? values.startTime : undefined,
          endDate: values.endDate,
          endTime: values.endTime ? values.endTime : undefined,
          group: values.group,
          responsibleMember: values.responsibleMember ? values.responsibleMember : userData.value,
          totalCost: '1.00',
          insuranceOptions: values.insuranceOptions ? values.insuranceOptions : [],
          maxCoverage: values.maxCoverage ? values.maxCoverage : undefined,
        })
        store.dispatch('setGeneralInsuranceState', generalInsuranceState)
        store.dispatch('setHolderState', HolderStates.TYPE)
      })()
    }

    const fetchMaxCoverages = () => {
      RepositoryFactory.get(MaxCoverageRepository)
        .getArray()
        .then((result: any) => {
          maxCoverageOptions.value = result
        })
    }

    const isRefreshing = ref<boolean>(false)
    const refreshGroups = () => {
      isRefreshing.value = true
      RepositoryFactory.get(AuthRepository)
        .me()
        .then((user: any) => {
          store.dispatch('setUser', user).then(() => {
            values.responsibleMember = store.getters.user
            isRefreshing.value = false
          })
        })
    }

    const isSavingDraft = ref<boolean>(false)

    const patchDraft = () => {
        const draftData = ref<BaseInsurance>({
        startDate: values.startDate,
        startTime: values.startTime ? values.startTime : undefined,
        endDate: values.endDate,
        endTime: values.endTime ? values.endTime : undefined,
        group: values.group,
        responsibleMember: values.responsibleMember ? values.responsibleMember : userData.value,
        totalCost: '1.00',
        insuranceOptions: values.insuranceOptions ? values.insuranceOptions : [],
        maxCoverage: values.maxCoverage ? values.maxCoverage : undefined,
      })

      if (!isSavingDraft.value) {
        isSavingDraft.value = true
        //@ts-ignore
        RepositoryFactory.get(InsuranceTypeRepos[insuranceTypeState.value])

          //@ts-ignore
          .patchDraft({...data, ...draftData.value}, insuranceTypeState.value, route.params.id)
          .then(() => {
            router.push('/home/verzekeringen')
          })
      }
    }

    const saveAsDraft = () => {
      const draftData = ref<BaseInsurance>({
        startDate: values.startDate,
        startTime: values.startTime ? values.startTime : undefined,
        endDate: values.endDate,
        endTime: values.endTime ? values.endTime : undefined,
        group: values.group,
        responsibleMember: values.responsibleMember ? values.responsibleMember : userData.value,
        totalCost: '1.00',
        insuranceOptions: values.insuranceOptions ? values.insuranceOptions : [],
        maxCoverage: values.maxCoverage ? values.maxCoverage : undefined,
      })

      if (!isSavingDraft.value) {
        isSavingDraft.value = true
        //@ts-ignore
        RepositoryFactory.get(InsuranceTypeRepos[insuranceTypeState.value])
          //@ts-ignore
          .createDraft(draftData.value, insuranceTypeState.value)
          .then(() => {
            router.push('/home/verzekeringen')
          })
      }
    }

    fetchMaxCoverages()
    scrollToTopOfPage()

    watch(
      () => insuranceTypeState.value,
      () => {
        if (insuranceTypeState.value === InsuranceTypes.TIJDELIJKE_AUTO_VERZEKERING) {
          values.insuranceOptions = []
        }
      }
    )

    watch(
      () => values.insuranceOptions,
      (current, old) => {
        if (old && current && old.includes(2) && current.includes(1)) {
          values.insuranceOptions = current.filter((item) => ![2].includes(item))
        }
        if (old && current && old.includes(1) && current.includes(2)) {
          values.insuranceOptions = current.filter((item) => ![1].includes(item))
        }
      }
    )

    return {
      maxCoverageOptions,
      insuranceTypeState,
      InsuranceTypes,
      refreshGroups,
      isSavingDraft,
      isSubmitting,
      isRefreshing,
      saveAsDraft,
      InputTypes,
      userData,
      onSubmit,
      minDate,
      isEdit,
      values,
      data,
      isDraftEdit,
      patchDraft
    }
  },
})
