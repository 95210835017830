
import { BaseRepository } from '@/repositories/baseRepository'
import RepositoryFactory from '@/repositories/repositoryFactory'
import { defineComponent, PropType, ref, watch } from 'vue'

export default defineComponent({
  name: 'SearchInputClaims',
  props: {
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Zoek...',
    },
    repository: {
      type: Function as unknown as PropType<new () => BaseRepository>,
      required: false,
      default: Function as unknown as PropType<new () => BaseRepository>,
    },
    loading: Boolean,
    loadingSubmit: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, context) {
    let debounce: any
    const query = ref<string>('')
    const year = ref<string>('')
    const options = ref<any>([])
    const loading_1 = ref<boolean>(false)
    const loading_2 = ref<boolean>(false)

    const search_1 = () => {
      loading_1.value = true
      clearTimeout(debounce)
      debounce = setTimeout(() => {
        if (query.value) {
          RepositoryFactory.get(props.repository)
            .search(query.value, year.value)
            .then((results: any) => {
              options.value = results
              context.emit('fetchedOptions', options.value)
              loading_1.value = false
            })
        }
      }, 1000)
    }

    const search_2 = () => {
      loading_2.value = true
      clearTimeout(debounce)
      debounce = setTimeout(() => {
          RepositoryFactory.get(props.repository)
            .search(query.value, year.value)
            .then((results: any) => {
              options.value = results
              context.emit('fetchedOptions', options.value)
              loading_2.value = false
            })
      }, 1000)
    }

    watch(() => query.value, () => {
      if (query.value === '') {
        loading_1.value = true
        RepositoryFactory.get(props.repository)
            .search(query.value, year.value)
            .then((results: any) => {
              options.value = results
              context.emit('fetchedOptions', options.value)
              loading_1.value = false
            })
      }
    })

    return {
      options,
      search_1,
      query,
      year,
      search_2,
      loading_1,
      loading_2
    }
  },
})
