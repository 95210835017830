import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex py-3 xs:flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bread_crumb_item = _resolveComponent("bread-crumb-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bread_crumb_item, {
      "is-change-state-possible": _ctx.claimHolderState === _ctx.ClaimHolderStates.TWO || _ctx.claimHolderState === _ctx.ClaimHolderStates.THREE || _ctx.claimHolderState === _ctx.ClaimHolderStates.FOUR,
      "visible-on-state": _ctx.ClaimHolderStates.ONE,
      index: '1',
      text: 'Slachtoffer'
    }, null, 8, ["is-change-state-possible", "visible-on-state"]),
    _createVNode(_component_bread_crumb_item, {
      "is-change-state-possible": _ctx.claimHolderState === _ctx.ClaimHolderStates.THREE || _ctx.claimHolderState === _ctx.ClaimHolderStates.FOUR,
      "visible-on-state": _ctx.ClaimHolderStates.TWO,
      index: '2',
      text: 'Ongeval'
    }, null, 8, ["is-change-state-possible", "visible-on-state"]),
    _createVNode(_component_bread_crumb_item, {
      "is-change-state-possible": _ctx.claimHolderState === _ctx.ClaimHolderStates.FOUR,
      "visible-on-state": _ctx.ClaimHolderStates.THREE,
      index: '3',
      text: 'Betrokkenen'
    }, null, 8, ["is-change-state-possible", "visible-on-state"]),
    _createVNode(_component_bread_crumb_item, {
      "is-change-state-possible": false,
      "visible-on-state": _ctx.ClaimHolderStates.FOUR,
      index: '4',
      text: 'Overzicht'
    }, null, 8, ["visible-on-state"]),
    _createVNode(_component_bread_crumb_item, {
      "is-change-state-possible": false,
      "visible-on-state": _ctx.ClaimHolderStates.FIVE,
      index: '5',
      text: 'Verzonden'
    }, null, 8, ["visible-on-state"])
  ]))
}