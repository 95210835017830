
import { NonMember } from '@/serializer/NonMember'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    nonMember: {
      type: Object as PropType<NonMember>,
      required: true,
    },
  },
})
