
import { ResponsibleMember } from '@/serializer/ResponsibleMember'
import LabelOutput from '@/components/semantic/LabelOutput.vue'
import { defineComponent, PropType } from 'vue'
import PhoneNumber from '@/components/semantic/PhoneNumber.vue'

export default defineComponent({
  name: 'ResponsibleMemberDetail',
  components: {
    'label-output': LabelOutput,
    'phone-number': PhoneNumber,
  },
  props: {
    responsibleMember: {
      type: Object as PropType<ResponsibleMember>,
      default: Object as PropType<ResponsibleMember>,
    },
    title: {
      type: String,
      required: false,
      default: 'Aanvrager',
    },
  },
})
