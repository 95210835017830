
import InfoAlert from '@/components/requestInsurance/InfoAlert.vue'
import ChoiceHelpItem from '@/components/semantic/ChoiceHelpItem.vue'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'TipChoiceHelp',
  components: {
    'info-alert': InfoAlert,
    'choice-help-item': ChoiceHelpItem,
  },
  setup() {
    const choices = ref<Array<boolean | string>>(['', '', '', '', '', ''])
    const text = ref<string>('')

    const singleChoiceText = 'Aan de hand van voorgaande vragen is de volgende optie het best geschikt voor je aanvraag: '
    const multipleChoicesText = 'Aan de hand van voorgaande vragen zijn de volgende opties het best geschikt voor je aanvraag: '
    const noChoiceText = 'Aan de hand van voorgaande vragen is gebleken dat je <strong>geen</strong> extra verzekering nodig hebt.'

    const displayText = () => {
      // NEE NEE NEE
      if (choices.value[0] === false && choices.value[3] === false && choices.value[5] === false) {
        text.value = noChoiceText
      }

      // NEE NEE JA
      if (choices.value[0] === false && choices.value[3] === false && choices.value[5] === true) {
        text.value = singleChoiceText + '<strong>optie 1</strong>'
      }

      // NEE JA NEE
      if (choices.value[0] === false && choices.value[3] === true && choices.value[4] === false) {
        text.value = noChoiceText
      }

      // NEE JA JA
      if (choices.value[0] === false && choices.value[3] === true && choices.value[4] === true) {
        text.value = singleChoiceText + '<strong>optie 2</strong>'
      }

      // JA NEE NEE NEE
      if (choices.value[0] === true && choices.value[1] === false && choices.value[3] === false && choices.value[5] === false) {
        text.value = noChoiceText
      }

      // JA NEE JA JA
      if (choices.value[0] === true && choices.value[1] === false && choices.value[3] === true && choices.value[4] === true) {
        text.value = singleChoiceText + '<strong>optie 2</strong>'
      }

      // JA NEE JA NEE
      if (choices.value[0] === true && choices.value[1] === false && choices.value[3] === true && choices.value[4] === false) {
        text.value = noChoiceText
      }

      // JA JA NEE NEE NEE
      if (choices.value[0] === true && choices.value[1] === true && choices.value[2] === false && choices.value[3] === false && choices.value[5] === false) {
        text.value = noChoiceText
      }

      // JA JA NEE NEE JA
      if (choices.value[0] === true && choices.value[1] === true && choices.value[2] === false && choices.value[3] === false && choices.value[5] === true) {
        text.value = singleChoiceText + '<strong>optie 1</strong>'
      }

      // JA JA NEE JA JA
      if (choices.value[0] === true && choices.value[1] === true && choices.value[2] === false && choices.value[3] === true && choices.value[4] === true) {
        text.value = singleChoiceText + '<strong>optie 2</strong>'
      }

      // JA JA NEE JA NEE
      if (choices.value[0] === true && choices.value[1] === true && choices.value[2] === false && choices.value[3] === true && choices.value[4] === false) {
        text.value = noChoiceText
      }

      // JA JA JA NEE NEE
      if (choices.value[0] === true && choices.value[1] === true && choices.value[2] === true && choices.value[3] === false && choices.value[5] === false) {
        text.value = singleChoiceText + '<strong>optie 3</strong>' // this one
      }

      // JA JA JA NEE JA
      if (choices.value[0] === true && choices.value[1] === true && choices.value[2] === true && choices.value[3] === false && choices.value[5] === true) {
        text.value = multipleChoicesText + '<strong>optie 1 & optie 3</strong>'
      }

      // JA JA JA JA NEE
      if (choices.value[0] === true && choices.value[1] === true && choices.value[2] === true && choices.value[3] === true && choices.value[4] === false) {
        text.value = singleChoiceText + '<strong>optie 3</strong>'
      }

      // JA JA JA JA JA
      if (choices.value[0] === true && choices.value[1] === true && choices.value[2] === true && choices.value[3] === true && choices.value[4] === true) {
        text.value = multipleChoicesText + '<strong>optie 2 & optie 3</strong>'
      }

      return text.value
    }

    const observeAndResetValues = (arrIndex: number) => {
      watch(
        () => choices.value[arrIndex],
        () => {
          for (let index = arrIndex + 1; index < choices.value.length; index++) {
            choices.value[index] = 'empty'
          }
        }
      )
    }

    observeAndResetValues(0)
    observeAndResetValues(1)
    observeAndResetValues(2)
    observeAndResetValues(3)
    observeAndResetValues(4)

    return {
      choices,
      displayText,
    }
  },
})
