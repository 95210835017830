
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'ChoiceHelpItem',
  props: {
    text: String,
    choice: [Boolean, String],
    id: String,
  },
  setup(props, context) {
    const isChecked = ref<boolean | string>('')

    const emit = (value: boolean) => {
      context.emit('update:choice', value)
    }

    watch(
      () => props.choice,
      () => {
        if (props.choice) {
          isChecked.value = props.choice
        }
      }
    )

    return {
      isChecked,
      emit,
    }
  },
})
