
import { FileItem } from '@/serializer/FileItem'
import FileRepository from '@/repositories/fileRepository'
import RepositoryFactory from '@/repositories/repositoryFactory'
import { defineComponent, PropType } from 'vue'
import { saveAs } from 'file-saver'

export default defineComponent({
  name: 'file-item-component',
  props: {
    file: {
      type: Object as PropType<FileItem>,
      required: true,
    },
    isDetailView: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisplay: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  setup(props, context) {
    const downloadFile = () => {
      context.emit('downloadFile', true)
      if (props.file && props.file.id) {
        RepositoryFactory.get(FileRepository)
          .downloadFile(props.file.id)
          .then((file: Blob) => {
            const savedAsFile = saveAs(file, props.file.name)
          })
      }
    }

    const deleteFromArray = () => {
      context.emit('deleteFile', true)
    }

    return {
      downloadFile,
      deleteFromArray,
    }
  },
})
