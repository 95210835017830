
import { BelgianCitySearchRepository } from '@/repositories/belgianCitySearchRepository'
import CustomHeadline2 from '@/components/customHeadlines/CustomHeadline2.vue'
import { InsuranceTypeRepos, InsuranceTypes } from '@/enums/insuranceTypes'
import { EventSizeRepository } from '@/repositories/eventSizeRepository'
import { EventInsurance } from '@/serializer/insurances/EventInsurance'
import RepositoryFactory from '@/repositories/repositoryFactory'
import MultiSelect from '@/components/inputs/MultiSelect.vue'
import CustomInput from '@/components/inputs/CustomInput.vue'
import BackButton from '@/components/semantic/BackButton.vue'
import { computed, defineComponent, ref, watch } from 'vue'
import { scrollToFirstError } from '@/veeValidate/helpers'
import CustomButton from '@/components/CustomButton.vue'
import Loader from '@/components/semantic/Loader.vue'
import { HolderStates } from '@/enums/holderStates'
import { InputTypes } from '@/enums/inputTypes'
import { useForm } from 'vee-validate'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import router from '@/router'

type eventInsuranceFormType = {
  nature: string
  location: Location
  eventSize: number
  comment: string
  vvksComment: string
}

export default defineComponent({
  name: 'EventInsurance',
  components: {
    'custom-headline-2': CustomHeadline2,
    'custom-button': CustomButton,
    'multi-select': MultiSelect,
    'custom-input': CustomInput,
    'back-button': BackButton,
    Loader,
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const data: any = store.getters.getCurrentInsuranceState
    const { handleSubmit, values, isSubmitting, validate } = useForm<eventInsuranceFormType>({
      initialValues: {
        nature: data.nature ? data.nature : '',
        location: data.location ? data.location : '',
        eventSize: data.eventSize ? data.eventSize : '',
        comment: data.comment ? data.comment : '',
        vvksComment: data.vvksComment ? data.vvksComment : '',
      },
    })
    const isEdit = !!route.params.id

    const generalInsuranceState = computed(() => {
      return store.state.insurance.generalInsuranceState
    })

    const eventSizes = ref<any[]>([])
    const fetchEventSizes = () => {
      RepositoryFactory.get(EventSizeRepository)
        .getArray()
        .then((result: any) => {
          eventSizes.value = result
        })
    }

    fetchEventSizes()

    watch(
      () => isSubmitting.value,
      () => {
        store.dispatch('setIsSubmittingState', isSubmitting.value)
      }
    )

    const onSubmit = async () => {
      await validate().then((validation: any) => scrollToFirstError(validation, 'EventInsurance'))
      handleSubmit(async (values: eventInsuranceFormType) => {
        const eventInsurance = ref<EventInsurance>({
          ...generalInsuranceState.value,
          ...{
            nature: values.nature,
            location: values.location,
            eventSize: values.eventSize,
            comment: values.comment ? values.comment : '',
            vvksComment: values.vvksComment ? values.vvksComment : '',
          },
        })

        //@ts-ignore
        await RepositoryFactory.get(InsuranceTypeRepos[store.getters.insuranceTypeState])
          //@ts-ignore
          .getCalculatedCost(eventInsurance.value)
          .then((cost: any) => {
            eventInsurance.value.totalCost = cost

            store.dispatch('setEventState', eventInsurance.value).then(() => {
              store.dispatch('setHolderState', HolderStates.DETAIL)
            })
          })
      })()
    }

    const insuranceTypeState = computed((): InsuranceTypes => {
      return store.state.insurance.insuranceTypeState
    })
    const isSavingDraft = ref<boolean>(false)
    const isDraftEdit = ref<boolean>(route.path.includes('draft-bewerken'))

    const patchDraft = () => {
      const draftData = ref<EventInsurance>({
        ...generalInsuranceState.value,
        ...{
          nature: values.nature,
          location: values.location,
          eventSize: values.eventSize,
          comment: values.comment ? values.comment : '',
        },
      })

      if (!isSavingDraft.value) {
        isSavingDraft.value = true
        //@ts-ignore
        RepositoryFactory.get(InsuranceTypeRepos[insuranceTypeState.value])
          //@ts-ignore
          .patchDraft(draftData.value, insuranceTypeState.value, route.params.id)
          .then(() => {
            router.push('/home/verzekeringen')
          })
      }
    }

    const saveAsDraft = () => {
      const draftData = ref<EventInsurance>({
        ...generalInsuranceState.value,
        ...{
          nature: values.nature,
          location: values.location,
          eventSize: values.eventSize,
          comment: values.comment ? values.comment : '',
        },
      })

      if (!isSavingDraft.value) {
        isSavingDraft.value = true
        //@ts-ignore
        RepositoryFactory.get(InsuranceTypeRepos[insuranceTypeState.value])
          //@ts-ignore
          .createDraft(draftData.value, insuranceTypeState.value)
          .then(() => {
            router.push('/home/verzekeringen')
          })
      }
    }

    return {
      BelgianCitySearchRepository,
      generalInsuranceState,
      isSavingDraft,
      HolderStates,
      saveAsDraft,
      eventSizes,
      InputTypes,
      onSubmit,
      values,
      isEdit,
      patchDraft,
      isDraftEdit
    }
  },
})
