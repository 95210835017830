
import CustomHeadline2 from '@/components/customHeadlines/CustomHeadline2.vue'
import { scrollToFirstError, useScrollToTop } from '@/veeValidate/helpers'
import { ClaimHolderStates } from '@/enums/ClaimholderStates'
import CustomInput from '@/components/inputs/CustomInput.vue'
import BackButton from '@/components/semantic/BackButton.vue'
import { defineComponent, computed, ref, watch } from 'vue'
import Required from '@/components/semantic/Required.vue'
import CustomButton from '@/components/CustomButton.vue'
import { ActivityTypes } from '@/enums/activityTypes'
import { useForm, ErrorMessage } from 'vee-validate'
import { Claim } from '@/serializer/claims/claim'
import { DamageTypes } from '@/enums/damageTypes'
import { InputTypes } from '@/enums/inputTypes'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import moment from 'moment'
import DisplayContentCheckBox from '@/components/semantic/displayContentCheckbox.vue'

export default defineComponent({
  name: 'AccidentDetails',
  components: {
    'display-content-checkbox': DisplayContentCheckBox,
    'custom-headline-2': CustomHeadline2,
    'custom-button': CustomButton,
    'custom-input': CustomInput,
    'back-button': BackButton,
    ErrorMessage,
    Required,
  },
  setup() {
    const maxDate = moment().format('YYYY-MM-DD')
    const { scrollToTopOfPage } = useScrollToTop()
    const route = useRoute()
    const store = useStore()
    const isEdit = !!route.params.id
    const selectedActivityType = ref<any>()
    const isDamage = ref<Array<boolean>>([])

    const { handleSubmit, values, validate, isSubmitting } = useForm<Claim>()

    const claimState = computed((): Claim => {
      return store.state.claim.claimState
    })

    const onSubmit = async () => {
      await validate().then((validation: any) => scrollToFirstError(validation, 'RequestInsuranceGeneral'))
      handleSubmit(async (values: any) => {
        const newClaimState = ref<Claim>({
          dateOfAccident: values.dateOfAccident,
          activity: values.activity,
          activityTypes: [selectedActivityType.value],
          isDamage: isDamage.value.length === 1 ? true : false,
          description: values.description,
          usedTransport: values.usedTransport,
          damage: values.damage,
          leadershipDescription: values.leadershipDescription ? values.leadershipDescription : undefined,
          damageType: values.damageType ? values.damageType : undefined,
          isLeadershipChecked: values.isLeadershipChecked ? values.isLeadershipChecked : null
        })

        store.dispatch('setClaimState', { ...claimState.value, ...newClaimState.value })
        store.dispatch('setClaimHolderState', ClaimHolderStates.THREE)
      })()
    }

    scrollToTopOfPage()

    watch(
      () => values.usedTransport,
      () => {
        if (values.usedTransport && values.usedTransport.length > 0) {
          selectedActivityType.value = ActivityTypes.TRANSPORT
        }
      }
    )

    watch(
      () => selectedActivityType.value,
      () => {
        values.activityTypes = [selectedActivityType.value]
        values.usedTransport = undefined
      }
    )

    watch(
      () => values.damage,
      () => {
        if (values.damage && values.damage.length > 0) {
          if (isDamage.value.length === 0) {
            isDamage.value = [true]
          }
        } else if (isDamage.value.includes(true)) {
          isDamage.value = []
        }
      }
    )

    const leaderShipCheck = (e: any) => {
      values.isLeadershipChecked = e
    }
    return {
      selectedActivityType,
      ClaimHolderStates,
      ActivityTypes,
      isSubmitting,
      DamageTypes,
      InputTypes,
      claimState,
      onSubmit,
      isDamage,
      isEdit,
      values,
      maxDate,
      leaderShipCheck
    }
  },
})
