
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PageTitle',
  setup() {
    const home = (url: any) => {
      return String(url)
    }
    return {
      home,
    }
  },
})
