
import RequestInsuranceGeneral from '@/components/requestInsurance/RequestInsuranceGeneral.vue'
import RequestInsuranceDetail from '@/components/requestInsurance/RequestInsuranceDetail.vue'
import RequestInsuranceType from '@/components/requestInsurance/RequestInsuranceType.vue'
import RequestInsuranceSubmit from '@/components/requestInsurance/RequestInsuranceSubmit.vue'
import { HolderStates } from '@/enums/holderStates'
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'HolderStateForm',
  components: {
    'request-insurance-general': RequestInsuranceGeneral,
    'request-insurance-detail': RequestInsuranceDetail,
    'request-insurance-submit': RequestInsuranceSubmit,
    'request-insurance-type': RequestInsuranceType,
  },
  setup() {
    const store = useStore()
    const holderState = computed((): HolderStates => {
      return store.state.insurance.holderState
    })
    return {
      HolderStates,
      holderState,
    }
  },
})
