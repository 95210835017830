
import { BelgianCitySearchRepository } from '@/repositories/belgianCitySearchRepository'
import DisplayContentCheckBox from '@/components/semantic/displayContentCheckbox.vue'
import CustomHeadline2 from '@/components/customHeadlines/CustomHeadline2.vue'
import { scrollToFirstError, useScrollToTop } from '@/veeValidate/helpers'
import BackButton from '@/components/semantic/BackButton.vue'
import { ClaimHolderStates } from '@/enums/ClaimholderStates'
import CustomInput from '@/components/inputs/CustomInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import { defineComponent, computed, ref } from 'vue'
import { Claim } from '@/serializer/claims/claim'
import { InputTypes } from '@/enums/inputTypes'
import { useForm } from 'vee-validate'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'AccidentDetails',
  components: {
    'display-content-checkbox': DisplayContentCheckBox,
    'custom-headline-2': CustomHeadline2,
    'custom-button': CustomButton,
    'custom-input': CustomInput,
    'back-button': BackButton,
  },
  setup() {
    const { scrollToTopOfPage } = useScrollToTop()
    const route = useRoute()
    const store = useStore()
    const isEdit = !!route.params.id
    const { handleSubmit, values, validate, isSubmitting } = useForm<Claim>({
      initialValues: {},
    })

    const claimState = computed((): Claim => {
      return store.state.claim.claimState
    })

    const onSubmit = async () => {
      await validate().then((validation: any) => scrollToFirstError(validation, 'RequestInsuranceGeneral'))
      handleSubmit(async (values: any) => {
        const newClaimState = ref<Claim>({
          involvedPartyName: values.involvedPartyName ? values.involvedPartyName : undefined,
          involvedPartyDescription: values.involvedPartyDescription ? values.involvedPartyDescription : undefined,
          involvedPartyBirthdate: values.involvedPartyBirthdate ? values.involvedPartyBirthdate : undefined,
          officialReportDescription: values.officialReportDescription ? values.officialReportDescription : undefined,
          pvNumber: values.pvNumber ? values.pvNumber : undefined,
          witnessName: values.witnessName ? values.witnessName : undefined,
          witnessDescription: values.witnessDescription ? values.witnessDescription : undefined,
          isWitnessChecked: values.isWitnessChecked ? values.isWitnessChecked : null,
          isOfficialReportChecked: values.isOfficialReportChecked ? values.isOfficialReportChecked : null,
          isInvolvedPartyChecked: values.isInvolvedPartyChecked ? values.isInvolvedPartyChecked : null
        })

        store.dispatch('setClaimState', { ...claimState.value, ...newClaimState.value })
        store.dispatch('setClaimHolderState', ClaimHolderStates.FOUR)
      })()
    }

    scrollToTopOfPage()

    const involvedPartyCheck = (e: any) => {
      values.isInvolvedPartyChecked = e

    }
    const officialReportCheck = (e: any) => {
      values.isOfficialReportChecked = e
    }
    const witnessCheck = (e: any) => {
      values.isWitnessChecked = e
    }

    return {
      BelgianCitySearchRepository,
      ClaimHolderStates,
      isSubmitting,
      InputTypes,
      claimState,
      onSubmit,
      isEdit,
      values,
      witnessCheck,
      officialReportCheck,
      involvedPartyCheck
    }
  },
})
