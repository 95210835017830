import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelText as _vModelText } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  style: {"font-size":"13px"}
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["min", "max", "type", "step", "maxlength", "name", "disabled", "placeholder", "oninvalid"]
const _hoisted_5 = ["id", "name", "disabled", "step"]
const _hoisted_6 = ["name", "disabled"]
const _hoisted_7 = ["name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_required = _resolveComponent("required")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.hideInput && _ctx.label)
      ? (_openBlock(), _createElementBlock("strong", _hoisted_1, [
          _createElementVNode("label", null, [
            _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
            _createVNode(_component_required, { rules: _ctx.rules }, null, 8, ["rules"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.extraInfo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", { innerHTML: _ctx.extraInfo }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", {
      class: _normalizeClass({
        'text-lightGray animate-pulse2 bg-lightGray': _ctx.loadingSubmit || _ctx.isSubmitting,
      })
    }, [
      (_ctx.type !== _ctx.InputTypes.TEXT_AREA && !_ctx.hideInput && _ctx.type !== _ctx.InputTypes.TIME)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
            class: _normalizeClass(["bg-lightGray p-2 min-w-0 w-100", { 'opacity-0': _ctx.loadingSubmit || _ctx.isSubmitting }]),
            min: _ctx.min,
            max: _ctx.max,
            type: _ctx.type,
            step: _ctx.step,
            maxlength: _ctx.maxlength,
            name: _ctx.name,
            disabled: _ctx.disabled || _ctx.loadingSubmit || _ctx.isSubmitting,
            placeholder: _ctx.placeholder,
            oninvalid: _ctx.onInvalid,
            oninput: "this.setCustomValidity('')"
          }, null, 10, _hoisted_4)), [
            [_vModelDynamic, _ctx.inputValue]
          ])
        : _createCommentVNode("", true),
      (_ctx.type === _ctx.InputTypes.TIME)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 1,
            id: _ctx.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputValue) = $event)),
            class: _normalizeClass(["bg-lightGray p-2 min-w-0 w-100", { 'opacity-0': _ctx.loadingSubmit || _ctx.isSubmitting }]),
            style: {"max-width":"100px"},
            type: "time",
            name: _ctx.name,
            disabled: _ctx.disabled || _ctx.loadingSubmit || _ctx.isSubmitting,
            step: _ctx.step
          }, null, 10, _hoisted_5)), [
            [_vModelText, _ctx.inputValue]
          ])
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.type === _ctx.InputTypes.TEXT_AREA && !_ctx.hideInput && _ctx.type !== _ctx.InputTypes.TIME)
      ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
          key: 2,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.inputValue) = $event)),
          class: _normalizeClass(["bg-lightGray p-2", 'md:' + _ctx.textAreaWidth + ' ' + 'xs:w-full']),
          type: 'text',
          name: _ctx.name,
          maxlength: "500",
          disabled: _ctx.disabled || _ctx.loadingSubmit || _ctx.isSubmitting
        }, null, 10, _hoisted_6)), [
          [_vModelText, _ctx.inputValue]
        ])
      : _createCommentVNode("", true),
    _createElementVNode("span", { name: _ctx.name }, [
      _createVNode(_component_ErrorMessage, {
        name: _ctx.name,
        class: "text-red text-sm block mt-1 w-80"
      }, null, 8, ["name"])
    ], 8, _hoisted_7)
  ]))
}