
import MemberItem from '@/components/insurances/travelAssistance/memberItem.vue'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { Member } from '@/serializer/Member'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'MembersList',
  components: {
    'member-item': MemberItem,
  },
  props: {
    membersList: {
      type: Array as PropType<Array<any>>,
      required: false,
      default: () => {
        return []
      },
    },
    canBeDeleted: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emit: ['deleteMemberFromList'],
  setup(props, context) {
    const members = ref<Member[]>(props.membersList)

    const store = useStore()

    const isSubmitting = computed((): boolean => {
      return store.state.insurance.isSubmittingState
    })

    watch(
      () => props.membersList,
      () => {
        members.value = props.membersList
      }
    )

    const deleteMemberFromList = (id: string) => {
      context.emit('deleteMemberFromList', id)
    }

    return {
      deleteMemberFromList,
      isSubmitting,
      members,
    }
  },
})
