import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_request_bread_crumb = _resolveComponent("request-bread-crumb")!
  const _component_state_form_holder = _resolveComponent("state-form-holder")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_request_bread_crumb),
      (_ctx.data.vvksComment && _ctx.holderState !== _ctx.HolderStates.COMPLETED && _ctx.holderState !== _ctx.HolderStates.DETAIL)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_state_form_holder)
    ])
  ], 64))
}