
import VehicleSiderbar from '@/components/insurances/travelAssistance/vehicleSideBar.vue'
import VehicleItem from '@/components/insurances/travelAssistance/vehicleItem.vue'
import { sideBarState } from 'vue-3-component-library'

import { ErrorMessage, useField } from 'vee-validate'
import { computed, defineComponent, ref } from 'vue'
import { Vehicle } from '@/serializer/Vehicle'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'SelectVehicle',
  components: {
    'vehicle-side-bar': VehicleSiderbar,
    'vehicle-item': VehicleItem,
    ErrorMessage,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    rules: {
      type: [Object, String, Function],
      default: '',
      required: false,
    },
  },
  setup(props) {
    const { value: vehicle } = useField<Vehicle | undefined>(props.id, props.rules, {
      initialValue: undefined,
    })
    const sideBarState = ref<sideBarState<Vehicle>>({ state: 'hide' })

    const isDisplay = ref<boolean>(false)

    const openSideBar = () => {
      sideBarState.value = { state: 'new' }
    }
    const addCreatedVehicle = (vehicleEvent: Vehicle) => {
      vehicle.value = vehicleEvent
    }

    const deleteVehicle = () => {
      vehicle.value = undefined
    }

    const vehicleToEdit = ref<Vehicle>()
    const isEdit = ref<boolean>(false)

    const editVehicle = (editVehicle: Vehicle) => {
      sideBarState.value = {
        state: 'edit',
        entity: editVehicle,
      }
    }

    const store = useStore()

    const isSubmitting = computed((): boolean => {
      return store.state.insurance.isSubmittingState
    })

    return {
      addCreatedVehicle,
      deleteVehicle,
      vehicleToEdit,
      sideBarState,
      isSubmitting,
      openSideBar,
      editVehicle,
      isDisplay,
      vehicle,
      isEdit,
    }
  },
})
