import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex gap-5 pt-1 pb-5 float-right" }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 4 }
const _hoisted_7 = {
  class: "pb-3",
  style: {"margin-top":"-2em"}
}
const _hoisted_8 = { class: "flex gap-5 pt-1 pb-5 float-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_call_to_action = _resolveComponent("call-to-action")!
  const _component_custom_list = _resolveComponent("custom-list")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_multi_select = _resolveComponent("multi-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_call_to_action, {
      link: "/aanvraag/verzekering",
      text: "Vraag nieuwe verzekering aan"
    }),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.drafts)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_custom_list, {
              "is-draft": true,
              items: _ctx.drafts.results,
              title: "Nog te voltooien",
              onRemoveDraft: _cache[0] || (_cache[0] = ($event: any) => (_ctx.removeDraft($event)))
            }, null, 8, ["items"]),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.drafts.previous)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    class: "link-inline cursor-pointer",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getPreviousDrafts(_ctx.drafts.previous)))
                  }, "Vorige pagina"))
                : _createCommentVNode("", true),
              (_ctx.drafts.next)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    class: "link-inline cursor-pointer",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getNextDrafts(_ctx.drafts.next)))
                  }, "Volgende pagina"))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.drafts)
        ? (_openBlock(), _createBlock(_component_custom_button, {
            key: 1,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.getDrafts())),
            text: "Onvoltooide verzekeringen"
          }))
        : _createCommentVNode("", true),
      (!_ctx.drafts)
        ? (_openBlock(), _createElementBlock("br", _hoisted_4))
        : _createCommentVNode("", true),
      (!_ctx.drafts)
        ? (_openBlock(), _createElementBlock("br", _hoisted_5))
        : _createCommentVNode("", true),
      (_ctx.data)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_custom_list, {
              items: _ctx.data.results,
              title: "Recent aangevraagd"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_multi_select, {
                    id: "group",
                    object: true,
                    placeholder: "Filter op groep",
                    onAddSelection: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addSelectionInsurances($event))),
                    "track-by": "fullInfo",
                    "value-prop": "id",
                    options: _ctx.userData.scoutsGroups,
                    "loading-submit": _ctx.isSubmitting
                  }, null, 8, ["options", "loading-submit"])
                ])
              ]),
              _: 1
            }, 8, ["items"]),
            _createElementVNode("div", _hoisted_8, [
              (_ctx.data.previous)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    class: "link-inline cursor-pointer",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.getPreviousInsurances(_ctx.data.previous)))
                  }, "Vorige pagina"))
                : _createCommentVNode("", true),
              (_ctx.data.next)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    class: "link-inline cursor-pointer",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.getNextInsurances(_ctx.data.next)))
                  }, "Volgende pagina"))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.data)
        ? (_openBlock(), _createBlock(_component_custom_button, {
            key: 5,
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.getInsurances())),
            text: "Recent aangevraagde verzekeringen"
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}