
import { defineComponent } from 'vue'
import NavigationArrow from '@/components/semantic/NavigationArrow.vue'

export default defineComponent({
  name: 'RemarksDetail',
  components: {
    'navigation-arrow': NavigationArrow,
  },
  props: {},
})
