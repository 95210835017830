
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Required',
  props: {
    rules: {
      type: [Object, String, Function],
      default: '',
      required: false,
    },
  },
})
