
import InfoAlert from '@/components/requestInsurance/InfoAlert.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TipGeneralMaterialInsurance',
  components: {
    'info-alert': InfoAlert,
  },
  setup() {
    return {}
  },
})
