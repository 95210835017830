
import { TemporaryVehicleRepository } from '@/repositories/insurances/temporaryVehicleRepository'
import ResponsibleMemberDetail from '@/components/semantic/detail/ResponsibleMemberDetail.vue'
import VehicleItem from '@/components/insurances/travelAssistance/vehicleItem.vue'
import MemberList from '@/components/insurances/travelAssistance/memberList.vue'
import Owner from '@/components/insurances/tempCarInsurance/owner.vue'
import BaseDetail from '@/components/semantic/detail/BaseDetail.vue'
import LabelOutput from '@/components/semantic/LabelOutput.vue'
import { formatDate } from '@/helpers/formatHelper'
import { HolderStates } from '@/enums/holderStates'
import { computed, defineComponent } from 'vue'
import { InputTypes } from '@/enums/inputTypes'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'TemporaryVehicleDetail',
  components: {
    'responsible-member-detail': ResponsibleMemberDetail,
    'label-output': LabelOutput,
    'vehicle-item': VehicleItem,
    'base-detail': BaseDetail,
    'member-list': MemberList,
    Owner,
  },
  props: {
    singlePage: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup() {
    const store = useStore()

    const temporaryVehicleState = computed(() => {
      return store.state.insurance.temporaryVehicleState
    })

    const holderState = computed((): HolderStates => {
      return store.state.insurance.holderState
    })

    return {
      TemporaryVehicleRepository,
      temporaryVehicleState,
      formatDate,
      InputTypes,
      HolderStates,
      holderState,
    }
  },
})
