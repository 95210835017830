import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_request_bread_crumb = _resolveComponent("request-bread-crumb")!
  const _component_state_claim_form_holder = _resolveComponent("state-claim-form-holder")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_request_bread_crumb)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_state_claim_form_holder)
    ])
  ], 64))
}