import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = { class: "mb-5" }
const _hoisted_3 = {
  key: 0,
  class: "p-3 w-72 rounded-lg bg-lightGreen"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_headline_2 = _resolveComponent("custom-headline-2")!
  const _component_file_upload = _resolveComponent("file-upload")!
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("form", {
      id: "list",
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onSubmit(_ctx.det)), ["prevent"]))
    }, [
      _createVNode(_component_custom_headline_2, { text: "Bijlage" }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_file_upload, {
          onRemoveUploadedFile: _cache[0] || (_cache[0] = ($event: any) => (_ctx.removeUploadedFile())),
          inscuranceType: _ctx.inscuranceType,
          file: _ctx.det.participant_list_file,
          message: 'Deelnemerslijst kan hier opgeladen worden.'
        }, null, 8, ["inscuranceType", "file", "message"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.displayMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Bijlage succesvol ingediend! "))
          : _createCommentVNode("", true),
        (!_ctx.det.participant_list_file)
          ? (_openBlock(), _createBlock(_component_custom_button, {
              key: 1,
              loadingSubmit: _ctx.isUploadingFile,
              text: "Bijlage indienen"
            }, null, 8, ["loadingSubmit"]))
          : _createCommentVNode("", true)
      ])
    ], 32)
  ]))
}