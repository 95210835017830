import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_request_claim_identities = _resolveComponent("request-claim-identities")!
  const _component_accident_details = _resolveComponent("accident-details")!
  const _component_involvement_other_parties = _resolveComponent("involvement-other-parties")!
  const _component_claim_detail = _resolveComponent("claim-detail")!
  const _component_success_screen = _resolveComponent("success-screen")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_request_claim_identities)
    ], 512), [
      [_vShow, _ctx.claimHolderState === _ctx.ClaimHolderStates.ONE]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_accident_details)
    ], 512), [
      [_vShow, _ctx.claimHolderState === _ctx.ClaimHolderStates.TWO]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_involvement_other_parties)
    ], 512), [
      [_vShow, _ctx.claimHolderState === _ctx.ClaimHolderStates.THREE]
    ]),
    (_ctx.claimHolderState === _ctx.ClaimHolderStates.FOUR)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_claim_detail)
        ]))
      : _createCommentVNode("", true),
    (_ctx.claimHolderState === _ctx.ClaimHolderStates.FIVE)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_success_screen)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}