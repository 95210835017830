
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PhoneNumber',
  props: {
    phoneNumber: String,
    hasWarning: Boolean,
  },
})
