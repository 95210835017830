
import OneTimeActivityDetail from '@/components/insurances/oneTimeActivityInsurance/oneTimeActivityDetail.vue'
import NonMemberInsuranceDetail from '@/components/insurances/nonMembersInsurance/nonMemberDetail.vue'
import TravelAssistanceDetail from '@/components/insurances/travelAssistance/travelAssistanceDetail.vue'
import EventDetail from '@/components/insurances/eventInsurance/eventInsuranceDetail.vue'
import MaterialInsuranceDetail from '@/components/insurances/materialInsurance/materialInsuranceDetail.vue'
import { isCurrentType } from '@/helpers/insuranceTypeHelper'
import { InsuranceTypes } from '@/enums/insuranceTypes'
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import TemporaryVehicleDetail from '../insurances/tempCarInsurance/temporaryVehicleDetail.vue'

export default defineComponent({
  name: 'RequestInsuranceDetail',
  components: {
    'one-time-activity-detail': OneTimeActivityDetail,
    'non-member-insurance-detail': NonMemberInsuranceDetail,
    'travel-assistance-detail': TravelAssistanceDetail,
    'event-detail': EventDetail,
    'material-insurance-detail': MaterialInsuranceDetail,
    TemporaryVehicleDetail,
  },
  setup() {
    const store = useStore()

    const insuranceTypeState = computed((): InsuranceTypes => {
      return store.state.insurance.insuranceTypeState
    })

    return {
      insuranceTypeState,
      InsuranceTypes,
      isCurrentType,
    }
  },
})
